const useRecordingButton = (callState) => {
  let buttonColor = "primary";
  if (callState === "user_speaking") {
    buttonColor = "primary";
  } else if (callState === "user_listening") {
    buttonColor = "primary";
  } else {
    buttonColor = "primary";
  }

  return { buttonColor };
};

export default useRecordingButton;
