import React, { forwardRef, useState, useRef } from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKFileUploadRoot from "components/MKFileUpload/MKFileUploadRoot";
import {
  InsertDriveFile,
  PictureAsPdf,
  AudioFile,
  VideoFile,
  Description,
  Close,
} from "@mui/icons-material";

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  const iconProps = { sx: { fontSize: 40 } };
  switch (extension) {
    case "pdf":
      return <PictureAsPdf {...iconProps} />;
    case "mp3":
    case "wav":
      return <AudioFile {...iconProps} />;
    case "mp4":
    case "avi":
    case "mov":
      return <VideoFile {...iconProps} />;
    case "doc":
    case "docx":
    case "txt":
      return <Description {...iconProps} />;
    default:
      return <InsertDriveFile {...iconProps} />;
  }
};

const MKFileUpload = forwardRef(
  ({ onChange, accept, multiple, disabled, ...rest }, ref) => {
    const [files, setFiles] = useState([]);
    const [isDragActive, setIsDragActive] = useState(false);
    const fileInputRef = useRef(null);

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setIsDragActive(true);
      } else if (e.type === "dragleave") {
        setIsDragActive(false);
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFiles(e.dataTransfer.files);
      }
    };

    const handleChange = (e) => {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        handleFiles(e.target.files);
      }
    };

    const handleFiles = (selectedFiles) => {
      const newFiles = multiple
        ? Array.from(selectedFiles)
        : [selectedFiles[0]];
      setFiles(newFiles);
      if (onChange) {
        onChange(newFiles);
      }
    };

    const handleClick = () => {
      fileInputRef.current.click();
    };

    const handleClearFiles = () => {
      setFiles([]);
      if (onChange) {
        onChange([]);
      }
    };

    return (
      <MKFileUploadRoot
        ref={ref}
        onDragEnter={disabled ? null : handleDrag}
        onDragLeave={disabled ? null : handleDrag}
        onDragOver={disabled ? null : handleDrag}
        onDrop={disabled ? null : handleDrop}
        onClick={disabled ? null : handleClick}
        ownerState={{ isDragActive, hasFiles: files.length > 0, disabled }}
        {...rest}
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleChange}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          style={{ display: "none" }}
        />
        {files.length > 0 ? (
          <MKBox
            mt={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="relative"
            width="100%"
          >
            <MKBox
              position="absolute"
              top={-8}
              right={-8}
              onClick={(e) => {
                e.stopPropagation();
                handleClearFiles();
              }}
              sx={{ cursor: "pointer" }}
            >
              <Close color="error" />
            </MKBox>
            <MKBox display="flex" flexWrap="wrap" justifyContent="center">
              {files.map((file, index) => (
                <MKBox
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  m={1}
                  bgColor="grey.100"
                  borderRadius="lg"
                  shadow="sm"
                  sx={{
                    width: "80px",
                    height: "80px",
                    padding: "8px",
                  }}
                >
                  {getFileIcon(file.name)}
                  <MKTypography
                    variant="caption"
                    mt={1}
                    color="text"
                    fontWeight="regular"
                    textTransform="lowercase"
                    sx={{ wordBreak: "break-word", textAlign: "center" }}
                  >
                    {file.name.length > 10
                      ? `${file.name.substring(0, 7)}...`
                      : file.name}
                  </MKTypography>
                </MKBox>
              ))}
            </MKBox>
          </MKBox>
        ) : (
          <MKBox>
            <MKTypography
              variant="h6"
              color={disabled ? "text.secondary" : "text"}
              fontWeight="medium"
              mb={1}
            >
              {disabled
                ? "File upload available only for clients"
                : "Drag and drop files here or click to select"}
            </MKTypography>
            {disabled && (
              <MKTypography
                variant="caption"
                color="info"
                component="a"
                href="https://calendly.com/ivelin-pxm/15min"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none" }}
              >
                Upgrade your plan to unlock this feature
              </MKTypography>
            )}
          </MKBox>
        )}
      </MKFileUploadRoot>
    );
  },
);

MKFileUpload.defaultProps = {
  multiple: false,
  disabled: false,
};

MKFileUpload.propTypes = {
  onChange: PropTypes.func,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

MKFileUpload.displayName = "MKFileUpload";

export default MKFileUpload;
