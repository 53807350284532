/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// @mui icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CenteredFooter({
  company,
  links,
  socials,
  light,
  transparent,
  showCompanyName,
}) {
  const { href, name } = company;

  const year = new Date().getFullYear();

  const renderLinks = links.map((link) => (
    <MKTypography
      key={link.name}
      component={Link}
      href={link.href}
      variant="body2"
      color={light ? "white" : "primary"}
      fontWeight="regular"
    >
      {link.name}
    </MKTypography>
  ));

  const renderSocials = socials.map((social) => (
    <MKTypography
      key={social.link}
      component={Link}
      href={social.link}
      variant="body2"
      color={light ? "white" : "primary"}
      fontWeight="regular"
    >
      {social.icon}
    </MKTypography>
  ));

  return (
    <MKBox
      component="footer"
      py={6}
      sx={{
        backgroundColor: transparent ? "transparent" : "defaultBackgroundColor",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={3}
            mt={1}
            mb={3}
          >
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="space-between"
              spacing={{ xs: 2, lg: 3, xl: 6 }}
            >
              <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="center"
                spacing={{ xs: 2, lg: 3, xl: 6 }}
              >
                {renderLinks}
              </Stack>
              <Stack direction="row" justifyContent="center" spacing={3}>
                {renderSocials}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {showCompanyName && (
          <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
            <MKTypography variant="body2" color={light ? "white" : "primary"}>
              Copyright &copy; {year}
              {" Skylar by "}
              <MKTypography
                component={Link}
                href={href}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                color={light ? "white" : "primary"}
              >
                {name}
              </MKTypography>
              .
            </MKTypography>
          </Grid>
        )}
      </Grid>
    </MKBox>
  );
}

// Setting default values for the props of CenteredFooter
CenteredFooter.defaultProps = {
  company: {
    href: "https://www.getskylar.com/enterprise",
    name: "Dialog Dynamo",
  },
  links: [
    { href: "https://www.getskylar.com/enterprise", name: "Company" },
    {
      href: "https://www.getskylar.com/terms-of-service",
      name: "Terms of Service",
    },
    { href: "https://calendly.com/ivelin-pxm/15min", name: "Contact Us" },
  ],
  socials: [
    {
      icon: <LinkedInIcon fontSize="small" />,
      link: "https://www.linkedin.com/company/getskylar/",
    },
  ],
  light: false,
  transparent: false,
  showCompanyName: false,
};

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  socials: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  light: PropTypes.bool,
  transparent: PropTypes.bool,
  showCompanyName: PropTypes.bool,
};

export default CenteredFooter;
