import confirmationTone from "../assets/audio/harp_ring.mp3";
import callSound from "../assets/audio/call_sound.mp3";
import pickUpSound from "../assets/audio/pick_up_desk_short.mp3";
import chimeSound from "../assets/audio/chime_not.mp3";

const roleplayConfig = {
  chris_voss: {
    firstCallViewTone: confirmationTone,
    secondCallViewTone: pickUpSound,
    courseId: 66,
  },
  cold_call_mone_easy: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 70,
  },
  cold_call_jimmy_easy: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 71,
  },
  cold_call_steve: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 91,
  },
  cold_call_multiverse_cdo: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 315,
  },
  cold_call_multiverse_research_based: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 316,
  },
  general_pitch_practice_multiverse: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 317,
  },
  cold_call_skylar_demo: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 321,
  },
  permission_based_opener: {
    firstCallViewTone: callSound,
    secondCallViewTone: chimeSound,
    courseId: 201,
  },
  cold_call_payhawk: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 150,
  },
  cold_call_fis_demo: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 501,
  },
  customer_service_demo: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 100,
  },
  cold_call_paypro_demo: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 401,
  },
  cold_call_paypro_demo_situation: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 402,
  },
  cold_call_paypro_pitch: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 403,
  },
  cold_call_saastock_competition: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 601,
  },
  roast_my_pitch: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 701,
  },
  custom_bot: {
    firstCallViewTone: callSound,
    secondCallViewTone: pickUpSound,
    courseId: 8001,
  },
};

export default roleplayConfig;
