// firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCIMXQiDZX6DZl49zuONPFUSF17W9y7v5Y",
  authDomain: "fir-frontend-v01.firebaseapp.com",
  projectId: "firebase-frontend-v01",
  storageBucket: "firebase-frontend-v01.appspot.com",
  messagingSenderId: "285540510775",
  appId: "1:285540510775:web:215e7a03511fe21918a3b2",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const functions = getFunctions(app);

if (process.env.REACT_APP_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099/");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  // connectAuthEmulator(auth, "https://192.168.1.148:443/identitytoolkit");
  // connectFirestoreEmulator(db, "192.168.1.148", 443);
  // // @ts-ignore
  // db["_settings"]["ssl"] = true;
}

export {
  app,
  auth,
  db,
  functions,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
};
