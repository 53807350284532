// ErrorModal.js

import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

const errorMessages = {
  no_text_transcribed:
    "We didn't hear you say anything. Can you please try again?",
  "websocket error":
    "We can't connect to our servers at the moment. Please contact ivelin@getskylar.com",
  "Error in playAudioBuffer": "Error in Playing Audio",
  "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.":
    "We can't hear you at all - microphone permissions don't seem to be working! Please allow microphone permissions from your browser so we can hear you. You can email us at ivelin@getskylar.com for help.",
  "Permission denied":
    "We can't hear you at all - microphone permissions don't seem to be working! Please allow microphone permissions from your browser so we can hear you. You can email us at ivelin@getskylar.com for help.",
  "Cannot destructure property":
    "Oops! Looks like your page refreshed unexpectedly. This might be caused by a browser extension. Try using a private browsing session to avoid this:\n\n" +
    "• On Mac: Command+Shift+N in Chrome or Safari\n" +
    "• On Windows: Ctrl+Shift+N in Chrome or Ctrl+Shift+P in Edge\n\n" +
    "This should help you continue without interruptions.",
  "vapiAssistantId is undefined":
    "We can't connect to our servers at the moment. Please contact ivelin@getskylar.com",
  // Add more error types here
};

// Transition component for the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorModal = forwardRef(({ error, resetErrorBoundary }, ref) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(true);

  console.log("error", error.message);

  const getErrorMessage = (errorMsg) => {
    for (const [key, value] of Object.entries(errorMessages)) {
      if (errorMsg.includes(key)) {
        return value;
      }
    }
    return "An unexpected error occurred.";
  };

  const errorMessage = getErrorMessage(error.message);

  const handleTryAgain = () => {
    setIsOpen(false);
    if (resetErrorBoundary) {
      resetErrorBoundary();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Transition}
      ref={ref}
    >
      <DialogTitle sx={{ typography: "h4" }}>{"Error Occurred"}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleTryAgain}
          color="info"
          variant="contained"
          style={{
            fontWeight: "normal",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
        >
          Try Again
        </Button>
      </DialogActions>
    </Dialog>
  );
});

ErrorModal.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func,
};

ErrorModal.displayName = "ErrorModal";

export default ErrorModal;
