import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";
import Grid from "@mui/material/Grid";
import httpService from "../../services/HttpService";
import LoadingSkeleton from "components/LoadingSkeleton";
import MKTypography from "components/MKTypography";

const MotionGrid = motion(Grid);
const MotionMKAvatar = motion(MKAvatar);
const MotionBox = motion(MKBox);

function StepTwo({ onSubmit }) {
  const [currentAvatar, setCurrentAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchNewAvatar = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpService.performGetRequest(
        "api/persona/get_avatar",
      );
      setCurrentAvatar({
        name: response.full_name,
        image: response.avatar_picture_url,
        personaId: response.ai_persona_identifier,
        audioUrl: response.introduction_audio_url,
      });
    } catch (err) {
      setError("Failed to fetch avatar");
      console.error("Error fetching avatar:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewAvatar();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(
      {
        name: currentAvatar.name,
        avatarUrl: currentAvatar.image,
      },
      2,
    );
  };

  return (
    <MotionGrid
      container
      item
      xs={12}
      lg={7}
      sx={{ mx: "auto" }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <MKBox
        width="100%"
        component="form"
        method="post"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <MKBox p={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              {isLoading ? (
                <LoadingSkeleton variant="circular" width={150} height={150} />
              ) : (
                <MotionMKAvatar
                  key={currentAvatar?.image}
                  src={currentAvatar?.image}
                  alt={currentAvatar?.name}
                  size="xxl"
                  shadow="xl"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                />
              )}
              <MKBox mt={2}>
                <MKButton
                  variant="contained"
                  color="primary"
                  onClick={fetchNewAvatar}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Choose Another Persona"}
                </MKButton>
              </MKBox>
              {error && (
                <MKBox mt={2}>
                  <MKTypography variant="caption" color="error">
                    {error}
                  </MKTypography>
                </MKBox>
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <MotionBox
                variant="contained"
                bgColor="light"
                color="dark"
                borderRadius="lg"
                shadow="md"
                py={1}
                px={2}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                key={currentAvatar?.name}
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                {isLoading ? (
                  <LoadingSkeleton width={200} />
                ) : (
                  currentAvatar?.name
                )}
              </MotionBox>
            </Grid>
            <Grid container item justifyContent="center" xs={12} mt={5}>
              <MKButton
                type="submit"
                variant="contained"
                color="dark"
                size="large"
                component={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading || !currentAvatar}
              >
                Create Persona
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </MotionGrid>
  );
}

StepTwo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StepTwo;
