import { useState, useEffect } from "react";

function useSequentialVisibility(delays) {
  const [visibilityStates, setVisibilityStates] = useState(
    delays.map(() => false),
  );

  useEffect(() => {
    // Calculate cumulative delays
    const cumulativeDelays = delays.map((delay, index) =>
      delays.slice(0, index + 1).reduce((a, b) => a + b, 0),
    );

    const timers = cumulativeDelays.map((cumulativeDelay, index) =>
      setTimeout(() => {
        setVisibilityStates((prevStates) => {
          const newStates = [...prevStates];
          newStates[index] = true;
          return newStates;
        });
      }, cumulativeDelay),
    );

    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [delays]);

  return visibilityStates;
}

export default useSequentialVisibility;
