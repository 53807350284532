export const createAssistantOverrides = ({
  selectedRoleplayId,
  customPersona,
  companyName,
  voiceUrl,
}) => {
  const overrides = {
    variableValues:
      selectedRoleplayId === "custom_bot"
        ? {
            full_name: customPersona.name,
            title: customPersona.title,
            company_name: customPersona.clientCompanyInfo.name,
            age: customPersona.age,
            company_description: customPersona.clientCompanyInfo.description,
            seller_company: customPersona.sellerCompanyName,
            location: customPersona.location,
            number_employees: customPersona.clientCompanyInfo.employeeCount,
            challenges: customPersona.challenges
              .map((challenge) => `  - ${challenge}`)
              .join("\n"),
          }
        : {
            companyName,
          },
  };

  if (voiceUrl) {
    overrides.voice = {
      inputPunctuationBoundaries: [".", "!", "?"],
      fillerInjectionEnabled: false,
      provider: "cartesia",
      voiceId: voiceUrl,
    };
  }

  console.log("overrides", overrides);

  return overrides;
};
