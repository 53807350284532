import { styled } from "@mui/material/styles";
import MKBox from "components/MKBox";

export default styled(MKBox)(({ theme, ownerState }) => {
  const { palette } = theme;
  const { grey, primary } = palette;
  const { disabled } = ownerState;

  return {
    position: "relative",
    border: `2px dashed ${disabled ? grey[300] : primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    textAlign: "center",
    cursor: disabled ? "default" : "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: disabled ? "transparent" : grey[200],
    },
    "& input": {
      display: "none",
    },
  };
});
