import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DashboardLayout from "layouts/DashboardLayout";
import { setLoading } from "features/loading/loadingSlice";
import { setCustomPersona } from "features/user/userSlice";
import { AuthContext } from "services/firebase/AuthContext";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import CompletionMessage from "./completionMessage";

const MotionContainer = motion(MKBox);
const MotionMKBox = motion(MKBox);

function BuildYourDynamicBot() {
  const dispatch = useDispatch();
  const { updateCustomPersona, authenticatedCurrentUser } =
    useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: { website: "" },
    step2: { name: "", avatarUrl: "" },
    step3: { initialMessage: "" },
  });

  const analysisData = useSelector(
    (state) => state.websiteAnalysis.analysisData,
  );

  const handleStepSubmit = async (stepData, step) => {
    dispatch(setLoading(true));

    setFormData((prev) => ({
      ...prev,
      [`step${step}`]: stepData,
    }));

    if (step === 3) {
      const personalInfo = {
        title:
          stepData.modifications?.title || analysisData?.replacements?.title,
        age: stepData.modifications?.age || analysisData?.replacements?.age,
        location:
          stepData.modifications?.location ||
          analysisData?.replacements?.location,
      };

      const clientCompanyInfo = {
        name:
          stepData.modifications?.company_name ||
          analysisData?.replacements?.company_name,
        employeeCount:
          stepData.modifications?.number_employees ||
          analysisData?.replacements?.number_employees,
        description:
          stepData.modifications?.company_description ||
          analysisData?.replacements?.company_description,
      };

      const customPersona = {
        website: formData.step1.website,
        name: formData.step2.name,
        avatarUrl: formData.step2.avatarUrl,
        ...personalInfo,
        callType: stepData.modifications?.callType || "cold_call",
        clientCompanyInfo,
        challenges:
          stepData.modifications?.challenges || analysisData?.challenges || [],
        sellerCompanyName: analysisData?.replacements?.seller_company || null,
      };

      try {
        dispatch(setCustomPersona(customPersona));
        await updateCustomPersona(authenticatedCurrentUser.uid, customPersona);
      } catch (error) {
        console.error("Failed to update custom persona:", error);
      }
    }

    dispatch(setLoading(false));
    setCurrentStep((prev) => prev + 1);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne formData={formData.step1} onSubmit={handleStepSubmit} />
        );
      case 2:
        return (
          <StepTwo formData={formData.step2} onSubmit={handleStepSubmit} />
        );
      case 3:
        return (
          <StepThree
            formData={formData.step3}
            persona={formData.step2}
            onSubmit={handleStepSubmit}
          />
        );
      default:
        return <CompletionMessage />;
    }
  };

  return (
    <DashboardLayout>
      <MKBox
        component="section"
        py={4}
        display="flex"
        justifyContent="center"
        minHeight="calc(100vh - 100px)"
      >
        <MotionContainer
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          width="100%"
          maxWidth="800px"
        >
          <MotionMKBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.2 }}
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            py={{ xs: 2, md: 6 }}
          >
            <MotionMKBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              textAlign="center"
              mb={4}
            >
              <MKTypography variant="h2" fontWeight="bold">
                Create Your AI Client
              </MKTypography>
              {currentStep <= 3 && (
                <MKTypography variant="subtitle1" mt={2}>
                  Step {currentStep} of 3
                </MKTypography>
              )}
            </MotionMKBox>
            <AnimatePresence mode="wait">{renderStepContent()}</AnimatePresence>
          </MotionMKBox>
        </MotionContainer>
      </MKBox>
    </DashboardLayout>
  );
}

export default BuildYourDynamicBot;
