import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { motion, useInView } from "framer-motion";

import Grid from "@mui/material/Grid";

import DashboardLayout from "layouts/DashboardLayout";
import MKBox from "components/MKBox";
import DataTable from "components/DataTable";
import MKTypography from "components/MKTypography";
import MultipleStatisticsCard from "components/StatisticsCards/MultipleStatisticsCard";
import { fetchOrganisationConversations } from "utils/OrganisationUtils";
import authorsTableData from "./authorsTableData";
import CallOfTheWeek from "./callOfTheWeek";

// Add this function at the top of your file, outside of the component
const getCurrentWeekNumber = () => {
  const now = new Date();
  const onejan = new Date(now.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((now - onejan) / 86400000 + onejan.getDay() + 1) / 7,
  );
  return weekNumber;
};

// Add these new functions at the top of the file, outside of the component
const calculateTotalMinutes = (conversationCount) => {
  return conversationCount * 2.5;
};

const calculateAverageScore = (rankedUsers) => {
  if (rankedUsers.length === 0) return 0;
  const totalScore = rankedUsers.reduce(
    (sum, user) => sum + user.average_score,
    0,
  );
  return (totalScore / rankedUsers.length).toFixed(2);
};

const MyReport = () => {
  const navigate = useNavigate();

  const orgId = useSelector((state) => state.user.orgId);
  const authLevel = useSelector((state) => state.user.authLevel);
  const [conversationCounts, setConversationCounts] = useState(0);
  const [rankedUsers, setRankedUsers] = useState([]);
  const [bestCall, setBestCall] = useState(null);

  const [animateStats, setAnimateStats] = useState(false);
  const [animateTeamRanking, setAnimateTeamRanking] = useState(false);

  const [totalMinutes, setTotalMinutes] = useState(0);
  const [averageScore, setAverageScore] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const statsRef = useRef(null);
  const teamRankingRef = useRef(null);

  const isStatsInView = useInView(statsRef, { once: true });
  const isTeamRankingInView = useInView(teamRankingRef, { once: true });

  useEffect(() => {
    if (orgId) {
      setIsLoading(true);
      fetchOrganisationConversations(orgId)
        .then(
          ({ totalConversations, rankedUsersAndConversations, bestCall }) => {
            setConversationCounts(totalConversations);
            setRankedUsers(rankedUsersAndConversations);
            setBestCall(bestCall);
            setTotalMinutes(calculateTotalMinutes(totalConversations));
            setAverageScore(calculateAverageScore(rankedUsersAndConversations));
            setIsLoading(false);
          },
        )
        .catch((error) => {
          console.error("Failed to fetch conversations", error);
          setIsLoading(false);
        });
    } else {
      navigate("/not-available");
    }
  }, [orgId, navigate]);

  useEffect(() => {
    if (isStatsInView) {
      setAnimateStats(true);
    }
    if (isTeamRankingInView) {
      setAnimateTeamRanking(true);
    }
  }, [isStatsInView, isTeamRankingInView]);

  const { columns, rows } = authorsTableData(rankedUsers);

  const currentWeek = getCurrentWeekNumber();

  const MotionGrid = motion(Grid);
  const MotionMKBox = motion(MKBox);
  const MotionMKTypography = motion(MKTypography);

  return (
    <DashboardLayout>
      <MotionMKBox
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        p={3}
      >
        <MKBox pb={3}>
          <MotionMKTypography
            variant="h4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            {`${authLevel ? authLevel.charAt(0).toUpperCase() + authLevel.slice(1) : "Loading..."} - Week ${currentWeek}`}
          </MotionMKTypography>
        </MKBox>
        <MKBox p={3} mb={3}>
          <MotionGrid container spacing={6} ref={statsRef}>
            {[
              {
                title: "Total Calls",
                count: isLoading ? "Loading..." : conversationCounts,
                color: "success",
                amount: "5%",
                label: "increase",
              },
              {
                title: "Average Score",
                count: isLoading ? "Loading..." : averageScore || "N/A",
                color:
                  !isLoading && averageScore
                    ? averageScore > 2
                      ? "success"
                      : "warning"
                    : "info",
                amount: isLoading ? "-" : "Pending",
                label: isLoading ? "loading" : "status",
              },
              {
                title: "Total Minutes Calling",
                count: isLoading ? "Loading..." : totalMinutes || "N/A",
                color: "info",
                amount: isLoading ? "-" : "Pending",
                label: isLoading ? "loading" : "status",
              },
            ].map((stat, index) => (
              <MotionGrid
                item
                xs={6}
                md={4}
                key={stat.title}
                initial={
                  animateStats ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 }
                }
                animate={animateStats ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
              >
                <MultipleStatisticsCard
                  data={[
                    {
                      title: stat.title,
                      count: stat.count,
                      percentage: {
                        color: stat.color,
                        amount: stat.amount,
                        label: stat.label,
                      },
                    },
                  ]}
                />
              </MotionGrid>
            ))}
          </MotionGrid>
        </MKBox>

        <MotionMKBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <CallOfTheWeek bestCall={bestCall} />
        </MotionMKBox>
        <MotionMKBox
          ref={teamRankingRef}
          initial={
            animateTeamRanking ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 }
          }
          animate={animateTeamRanking ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.7 }}
          pt={6}
          pb={3}
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MKBox bgColor="white" borderRadius="lg" shadow="xs">
                <MKBox p={3}>
                  <MKTypography variant="h5">
                    Team Ranking - Week 25
                  </MKTypography>
                </MKBox>
                <MKBox>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    pagination={{ variant: "gradient", color: "primary" }}
                  />
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
        </MotionMKBox>
      </MotionMKBox>
    </DashboardLayout>
  );
};

export default MyReport;
