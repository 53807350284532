import React, { useState } from "react";
import PropTypes from "prop-types";

import BaseModal from "./BaseModal";
import { useTheme } from "@mui/material/styles";
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import { isValidEmail } from "utils/validation";

const isValidInput = (type, value) => {
  switch (type) {
    case "email":
      // More robust email validation matching the backend pattern
      return isValidEmail(value);
    case "name":
      // Name validation: contains only letters and spaces.
      return /^[A-Za-z\s]+$/.test(value);
    default:
      // If no specific type, assume valid.
      return true;
  }
};

const TextSubmitModal = ({
  isOpen,
  title,
  onClose,
  closeOnActionOnly,
  actions,
  inputs,
  additionalContent,
}) => {
  // Use state to manage multiple inputs
  const [inputValues, setInputValues] = useState(
    inputs.reduce(
      (acc, input) => ({ ...acc, [input.name]: input.defaultValue || "" }),
      {},
    ),
  );

  const theme = useTheme();

  // Handle input change for multiple inputs
  const handleInputChange = (name, value) => {
    setInputValues((prev) => {
      const updatedValues = { ...prev, [name]: value };
      return updatedValues;
    });
  };

  // Modified onClose to check for required inputs before closing
  const handleClose = (action = false) => {
    if (closeOnActionOnly && !action) {
      // If closeOnActionOnly is true, do not proceed with the close logic
      return;
    }
    const allInputsValid = inputs.every((input) => {
      const value = inputValues[input.name].trim();
      return (
        !input.requireInput ||
        (input.requireInput && isValidInput(input.type, value))
      );
    });

    if (allInputsValid) {
      onClose(inputValues);
    } else {
      inputs.forEach((input) => {
        const value = inputValues[input.name].trim();
        if (input.requireInput && !isValidInput(input.type, value)) {
          alert(`Invalid ${input.type}: ${value}`);
        }
      });
    }
  };

  // Adjust actions to include the input check
  const modifiedActions = actions.map((action) => ({
    ...action,
    onClick: () => {
      const allInputsValid = inputs.every((input) => {
        const value = inputValues[input.name].trim();
        return (
          !input.requireInput ||
          (input.requireInput && isValidInput(input.type, value))
        );
      });

      if (allInputsValid) {
        handleClose(true);
        action.onClick(inputValues);
      } else {
        inputs.forEach((input) => {
          const value = inputValues[input.name].trim();
          if (input.requireInput && !isValidInput(input.type, value)) {
            alert(`Invalid ${input.type}: ${value}`);
          }
        });
      }
    },
  }));

  return (
    <BaseModal
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      actions={modifiedActions}
    >
      <MKBox>
        {additionalContent}
        {inputs.map((input, index) => (
          <MKBox key={index} mt={index !== 0 ? 2 : 0}>
            <MKInput
              key={index}
              type={input.type || "text"}
              multiline={input.minRows > 1}
              minRows={input.minRows || 1}
              value={inputValues[input.name]}
              onChange={(e) => handleInputChange(input.name, e.target.value)}
              placeholder={input.placeholder}
              fullWidth
              inputProps={{
                style: {
                  color: theme.palette.text.secondary,
                },
              }}
            />
          </MKBox>
        ))}
      </MKBox>
    </BaseModal>
  );
};

TextSubmitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnActionOnly: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      highlight: PropTypes.bool,
    }),
  ).isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      defaultValue: PropTypes.string,
      minRows: PropTypes.number,
      requireInput: PropTypes.bool,
      type: PropTypes.oneOf(["email", "name", "text"]),
    }),
  ).isRequired,
  additionalContent: PropTypes.node,
};

TextSubmitModal.defaultProps = {
  inputs: [
    {
      placeholder: "Enter some text...",
      minRows: 1,
      requireInput: false,
    },
  ],
  additionalContent: null,
  closeOnActionOnly: false,
};

export default TextSubmitModal;
