import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { updateConversationId } from "features/roleplayConfigDynamic/roleplayConfigDynamicSlice";
import { AuthContext } from "services/firebase/AuthContext";
import BaseModal from "components/BaseModal/BaseModal";
import CompanyNameInputModal from "./CompanyNameInputModal";
import BotChoiceModal from "components/BaseModal/BotChoiceModal";
import { createAssistantOverrides } from "utils/assistantOverridesUtil";

const UserOnboardingModal = ({
  playGreeting,
  company = "Dummy",
  companyDetails = "Dummy",
  level = null,
  vapiRef,
  vapiAssistantId,
  initialConversationId,
  voiceUrl,
  selectedRoleplay,
}) => {
  const [baseModalOpen, setBaseModalOpen] = useState(true); // Control BaseModal visibility independently
  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false); // New state for instructions modal visibility
  const [companyNameInputModalOpen, setCompanyNameInputModalOpen] = useState(
    company === "to be determined",
  );
  const [showBotChoice, setShowBotChoice] = useState(true);

  const { authenticatedCurrentUser, updateCurrentConversationId } =
    useContext(AuthContext);

  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(company);

  const { hasCreatedABot, authLevel } = useSelector((state) => state.user);

  const customPersona = useSelector((state) => state.user.customPersona);
  const selectedRoleplayId = useSelector(
    (state) => state.user.selectedRoleplay,
  );

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // Modify the handleBaseModalClose function to accept an optional newCompanyName parameter
  const handleBaseModalClose = async (newCompanyName = null) => {
    playGreeting();
    setBaseModalOpen(false);

    try {
      if (!vapiAssistantId) {
        console.error("vapiAssistantId is undefined");
        throw new Error("vapiAssistantId is undefined");
      }
      let vapiLog;
      if (newCompanyName || selectedRoleplayId === "custom_bot") {
        const assistantOverrides = createAssistantOverrides({
          selectedRoleplayId,
          customPersona,
          companyName: newCompanyName,
          voiceUrl: voiceUrl,
        });
        console.log("assistantOverrides", assistantOverrides);
        vapiLog = await vapiRef.current.start(
          vapiAssistantId,
          assistantOverrides,
        );
      } else {
        vapiLog = await vapiRef.current.start(vapiAssistantId);
      }
      console.debug("Vapi Object started", vapiLog);
      if (!vapiLog.id) {
        console.error("vapiLog is undefined");
        throw new Error("vapiLog is undefined");
      }
      const newConversationId = vapiLog.id;

      dispatch(
        updateConversationId({
          oldConversationId: initialConversationId,
          newConversationId: newConversationId,
        }),
      ); // to backend
      updateCurrentConversationId(
        authenticatedCurrentUser.uid,
        newConversationId,
      ); //to firebase
    } catch (error) {
      console.error("Error starting Vapi", error);
      setError(error);
    }
  };

  const handleInstructionsModalClose = () => {
    setInstructionsModalOpen(false);
    setBaseModalOpen(true);
  };

  // Update the handleCompanyNameSubmit function
  const handleCompanyNameSubmit = (newCompanyName) => {
    setCurrentCompany(newCompanyName);
    setCompanyNameInputModalOpen(false);
    handleBaseModalClose(newCompanyName);
  };

  const roleplayConfigDynamic = useSelector(
    (state) => state.roleplayConfigDynamic.data,
  );

  // Render the BaseModal
  const renderBaseModal = () => (
    <BaseModal
      isOpen={baseModalOpen}
      title="Instructions for the call"
      onClose={() => handleBaseModalClose()}
      actions={[
        {
          label: `Learn about ${currentCompany}`,
          onClick: () => setInstructionsModalOpen(true),
          highlight: false,
        },
        {
          label: "Let's go",
          onClick: () => handleBaseModalClose(),
          highlight: true,
        },
      ]}
    >
      {(roleplayConfigDynamic.call_instructions || "")
        .split("\n")
        .map((instruction, index) => (
          <p key={index}>{instruction}</p>
        ))}
      {level && (
        <p>
          Level of difficulty: <strong>{level}</strong>
        </p>
      )}{" "}
    </BaseModal>
  );

  const renderInstructionsModal = () => (
    <BaseModal
      isOpen={instructionsModalOpen}
      title={`About ${currentCompany}`}
      onClose={handleInstructionsModalClose}
      actions={[
        {
          label: "Back to instructions",
          onClick: handleInstructionsModalClose,
          highlight: true,
        },
      ]}
    >
      {companyDetails.split("\n").map((detail, index) => (
        <p key={index}>{detail}</p>
      ))}
    </BaseModal>
  );

  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    const determineModalContent = () => {
      if (
        showBotChoice &&
        !hasCreatedABot &&
        authenticatedCurrentUser &&
        authLevel === "general" &&
        selectedRoleplay !== "cold_call_saastock_competition"
      ) {
        return (
          <BotChoiceModal
            isOpen={true}
            onContinueWithSimulation={() => setShowBotChoice(false)}
          />
        );
      }

      if (companyNameInputModalOpen) {
        return (
          <CompanyNameInputModal
            isOpen={companyNameInputModalOpen}
            onClose={() => setCompanyNameInputModalOpen(false)}
            onSubmit={handleCompanyNameSubmit}
          />
        );
      } else if (instructionsModalOpen) {
        return renderInstructionsModal();
      } else {
        return renderBaseModal();
      }
    };

    setModalContent(determineModalContent());
  }, [
    baseModalOpen,
    roleplayConfigDynamic,
    instructionsModalOpen,
    companyNameInputModalOpen,
    currentCompany,
    showBotChoice,
    hasCreatedABot,
    authLevel,
  ]);

  // The render function now simply returns the modal content state
  return <>{modalContent}</>;
};

UserOnboardingModal.propTypes = {
  displayName: PropTypes.string.isRequired,
  playGreeting: PropTypes.func.isRequired,
  company: PropTypes.string,
  companyDetails: PropTypes.string,
  level: PropTypes.string,
  vapiRef: PropTypes.object,
  vapiAssistantId: PropTypes.string,
  initialConversationId: PropTypes.string,
  voiceUrl: PropTypes.string,
  selectedRoleplay: PropTypes.string,
};

export default UserOnboardingModal;
