// AssesmentUtils.js
import httpService from "../services/HttpService";

export function startAssessment(conversationId, onDataReceived) {
  return new Promise((resolve, reject) => {
    httpService
      .performPostRequestStream("perform-assessment", {
        conversation_id: conversationId,
      })
      .then((stream) => {
        const reader = stream.getReader();
        let decoder = new TextDecoder();
        let data = "";

        const processStream = ({ done, value }) => {
          if (done) {
            console.log("Stream complete");
            resolve();
            return;
          }
          data += decoder.decode(value, { stream: true });
          // Check for newline indicating end of a JSON object
          let dataIndex;
          while ((dataIndex = data.indexOf("\n")) !== -1) {
            let record = data.substring(0, dataIndex);
            data = data.substring(dataIndex + 1);
            if (record) {
              let result = JSON.parse(record);
              onDataReceived(result); // Call the callback for each piece of data
            }
          }
          reader.read().then(processStream);
        };

        reader.read().then(processStream);
      })
      .catch((error) => {
        console.error("Assessment error:", error);
        reject(error);
      });
  });
}

export function fetchTranscript(conversationId, onDataReceived) {
  return new Promise((resolve, reject) => {
    httpService
      .performPostRequestStream("fetch-transcript", {
        conversation_id: conversationId,
      })
      .then((stream) => {
        const reader = stream.getReader();
        let decoder = new TextDecoder();
        let data = "";
        const processStream = ({ done, value }) => {
          if (done) {
            console.log("Transcript stream complete");
            resolve(); // We don't need to pass anything here anymore
            return;
          }
          data += decoder.decode(value, { stream: true });
          // Check for newline indicating end of a JSON object
          let dataIndex;
          while ((dataIndex = data.indexOf("\n")) !== -1) {
            let record = data.substring(0, dataIndex);
            data = data.substring(dataIndex + 1);
            if (record) {
              let result = JSON.parse(record);
              if (result.type === "transcript") {
                const newTranscript = result.data.transcript.map((entry) => ({
                  actor: entry.actor,
                  message: entry.message,
                }));
                const newCallRecordingUrl = result.data.call_recording_url;
                // Call the callback function with new data
                onDataReceived({
                  type: "transcript",
                  transcript: newTranscript,
                  callRecordingUrl: newCallRecordingUrl,
                });
              } else if (result.type === "extracted_data") {
                // Call the callback function with new data
                onDataReceived({
                  type: "extractedData",
                  data: result.data,
                });
              }
            }
          }
          reader.read().then(processStream);
        };

        reader.read().then(processStream);
      })
      .catch((error) => {
        console.error("Transcript fetch error:", error);
        reject(error);
      });
  });
}
