import React, { useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import ChatBox from "components/ChatBox";
import PlaybackPlayerHolder from "components/PlaybackPlayerHolder";
import ExpandableSection from "./ExpandableSection";
import ClickableItem from "./ClickableItem";

const ConversationBox = ({ messages, callRecordingUrl, extractedData }) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(null);

  const handleItemClick = (timestamp) => {
    setCurrentTimestamp(timestamp);
  };
  return (
    <>
      <MKBox
        bgColor="white"
        borderRadius="xl"
        shadow="lg"
        p={1}
        mt={2}
        width={{ xs: "90%", sm: "80%", md: "70%" }}
      >
        <MKBox>
          {callRecordingUrl ? (
            <PlaybackPlayerHolder
              url={callRecordingUrl}
              timestamp={currentTimestamp}
            />
          ) : (
            <MKTypography
              variant="body2"
              color="text"
              textAlign="center"
              py={2}
            >
              Loading audio player...
            </MKTypography>
          )}
        </MKBox>
      </MKBox>
      <MKBox width={{ xs: "90%", sm: "80%", md: "70%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              mt={{ xs: 8, sm: 7, md: 4 }}
              maxWidth={{ sm: "500px", md: "1000px" }}
            >
              <MKBox display="flex" flexDirection="column" gap={1}>
                <ExpandableSection
                  title="Objections"
                  isLoading={!extractedData}
                >
                  <MKTypography
                    variant="body2"
                    color="text"
                    component="ul"
                    pl={1}
                  >
                    {extractedData?.objections?.map((objection, index) => (
                      <ClickableItem
                        key={index}
                        content={objection.content}
                        timestamp={objection.timestamp}
                        onClick={handleItemClick}
                      />
                    ))}
                  </MKTypography>
                </ExpandableSection>

                <ExpandableSection title="Questions" isLoading={!extractedData}>
                  <MKTypography
                    variant="body2"
                    color="text"
                    component="ul"
                    pl={1}
                  >
                    {extractedData?.questions?.map((question, index) => (
                      <ClickableItem
                        key={index}
                        content={question.content}
                        timestamp={question.timestamp}
                        onClick={handleItemClick}
                      />
                    ))}
                  </MKTypography>
                </ExpandableSection>

                <ExpandableSection title="Pitch" isLoading={!extractedData}>
                  <MKTypography
                    variant="body2"
                    color="text"
                    component="ul"
                    pl={1}
                  >
                    {extractedData?.pitches?.map((pitch, index) => (
                      <ClickableItem
                        key={index}
                        content={pitch.content}
                        timestamp={pitch.timestamp}
                        onClick={handleItemClick}
                      />
                    ))}
                  </MKTypography>
                </ExpandableSection>
              </MKBox>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              mt={{ xs: 8, sm: 7, md: 4 }}
              maxWidth={{ sm: "500px", md: "1000px" }}
            >
              <MKBox p={2}>
                <MKTypography variant="h5" color="text">
                  Transcript
                </MKTypography>
                <Divider />
              </MKBox>
              <MKBox>
                {messages.length > 0 ? (
                  <ChatBox messages={messages} />
                ) : (
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="center"
                    py={2}
                  >
                    Loading transcript...
                  </MKTypography>
                )}
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
};

// Define prop types for ConversationBox
ConversationBox.propTypes = {
  messages: PropTypes.array.isRequired,
  callRecordingUrl: PropTypes.string.isRequired,
  extractedData: PropTypes.shape({
    questions: PropTypes.arrayOf(PropTypes.string),
    objections: PropTypes.arrayOf(PropTypes.string),
    pitches: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ConversationBox;
