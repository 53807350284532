const categoryDescriptions = {
  Pitch:
    "We'll be looking at:\n" +
    "• How well you tailored your pitch to the target persona\n" +
    "• Whether you identified the key problems they're facing\n" +
    "• How clearly you explained your solution\n" +
    "• The way you highlighted positive outcomes",

  "Answering Questions":
    "We'll be considering:\n" +
    "• How clear and precise your responses were\n" +
    "• Whether you addressed the client's specific needs\n" +
    "• How well you listened and engaged with the client\n" +
    "• Your ability to keep answers concise and focused",

  "Objection Handling":
    "We'll be evaluating:\n" +
    "• How empathetically you acknowledged the client's concerns\n" +
    "• Whether you clarified what the objection was\n" +
    "• Your ability to reframe objections as opportunities\n" +
    "• How effectively you used social proof and data\n" +
    "• Whether you confirmed that the objection was resolved",
};

export default categoryDescriptions;
