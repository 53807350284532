import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

import DashboardLayout from "layouts/DashboardLayout";
import NotAvailable from "pages/NotAvailable";
import BuildYourDynamicBot from "pages/BuildYourDynamicBot";

// Create motion components
const MotionContainer = motion(Container);
const MotionGrid = motion(Grid);
const MotionMKBox = motion(MKBox);
const MotionMKButton = motion(MKButton);

function BuildYourBot() {
  const [checked, setChecked] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, authLevel } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleChecked = () => setChecked(!checked);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      navigate("/my-simulations");
    }, 3000);
  };

  if (isAuthenticated) {
    return <BuildYourDynamicBot />;
  }

  if (authLevel === null || authLevel === "general") {
    return <NotAvailable />;
  }

  return (
    <DashboardLayout>
      <MKBox component="section" py={6}>
        <MotionContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* Add the animated heading here */}
          <MotionMKBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            textAlign="center"
            mb={4}
          >
            <MKTypography variant="h2" fontWeight="bold">
              Build Your Own Bot
            </MKTypography>
          </MotionMKBox>

          <AnimatePresence>
            {showAlert && (
              <MotionMKBox
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.3 }}
              >
                <MKAlert color="success" dismissible>
                  Your request has been received. We&apos;ll contact you when
                  it&apos;s ready.
                </MKAlert>
              </MotionMKBox>
            )}
          </AnimatePresence>

          <MotionGrid
            container
            item
            xs={12}
            lg={7}
            sx={{ mx: "auto" }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <MKBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <MKBox p={3}>
                <MotionGrid container spacing={3}>
                  {[
                    { label: "Product", md: 6 },
                    {
                      label: "Type of Call (e.g. Discovery, Demo, etc.)",
                      md: 6,
                    },
                    { label: "Prospect's Role", md: 6 },
                    { label: "Industry", md: 6 },
                    {
                      label: "Pain Points Your Product Solves",
                      md: 12,
                      multiline: true,
                      rows: 3,
                    },
                    {
                      label: "Typical objections",
                      md: 12,
                      multiline: true,
                      rows: 2,
                    },
                  ].map((field, index) => (
                    <MotionGrid
                      key={field.label}
                      item
                      xs={12}
                      md={field.md}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.1 * index }}
                    >
                      <MKInput
                        variant="standard"
                        label={field.label}
                        fullWidth
                        multiline={field.multiline}
                        rows={field.rows}
                      />
                    </MotionGrid>
                  ))}

                  <MotionGrid
                    item
                    xs={12}
                    alignItems="center"
                    ml={-1}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.7 }}
                  >
                    <Switch checked={checked} onChange={handleChecked} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      ml={-1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      onClick={handleChecked}
                    >
                      &nbsp;&nbsp;I acknowledge the&nbsp;
                    </MKTypography>
                    <MKTypography
                      component="a"
                      href="#"
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                    >
                      Terms and Conditions
                    </MKTypography>
                  </MotionGrid>
                </MotionGrid>

                <MotionGrid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  my={2}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.8 }}
                >
                  <MotionMKButton
                    type="submit"
                    variant="gradient"
                    color="dark"
                    fullWidth
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Send Request
                  </MotionMKButton>
                </MotionGrid>
              </MKBox>
            </MKBox>
          </MotionGrid>
        </MotionContainer>
      </MKBox>
    </DashboardLayout>
  );
}

export default BuildYourBot;
