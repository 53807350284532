import React from "react";
import PropTypes from "prop-types";

import CssBaseline from "@mui/material/CssBaseline";
import MKBox from "./components/MKBox";
import DefaultNavbar from "./components/Navbars/DefaultNavbar";
import InformationModal from "./components/BaseModal/InformationModal";
import CustomActionModal from "./components/BaseModal/CustomActionModal";
import Sidenav from "./components/Navbars/SideNavbar";
import CrispChat from "./components/CrispChat/crispChat";
import SkylarLogo from "./assets/images/logo/skylar_logo_clean.png";

const AppLayout = ({
  children,
  sidenavRoutes,
  pagenavRoutes,
  isTransparentNavBar,
  layout,
}) => {
  return (
    <>
      <CssBaseline />
      <InformationModal />
      <CustomActionModal />
      <div>
        {layout === "dashboard" && (
          <Sidenav
            brand={SkylarLogo}
            brandName="SKYLAR"
            routes={sidenavRoutes}
          />
        )}
        {layout === "page" && (
          <MKBox bgColor="primary" shadow="sm">
            <DefaultNavbar
              routes={pagenavRoutes}
              action={{
                type: "external",
                route: "https://calendly.com/ivelin-pxm/15min",
                label: "Get a Demo",
                color: "white",
              }}
              brandRoute="/"
              brandName="SKYLAR"
              brand={SkylarLogo}
              transparent
              relative={!isTransparentNavBar}
              light
              center
            />
          </MKBox>
        )}

        {children}
        <CrispChat />
      </div>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidenavRoutes: PropTypes.array.isRequired,
  pagenavRoutes: PropTypes.array.isRequired,
  layout: PropTypes.string.isRequired,
  isTransparentNavBar: PropTypes.bool.isRequired,
  authenticatedCurrentUser: PropTypes.object,
  handleSignOut: PropTypes.func.isRequired,
};

export default AppLayout;
