import { useState, useCallback, useEffect } from "react";
import { useAnimation } from "framer-motion";

export const nameCardVariants = {
  hidden: { y: "-200%" },
  visible: {
    y: 0,
    transition: { duration: 1, ease: "easeInOut" },
  },
};

export const imageVariants = {
  hidden: { clipPath: "inset(0% 0% 100% 0%)" },
  visible: {
    clipPath: "inset(0% 0% 0% 0%)",
    transition: { duration: 1, ease: "easeInOut" },
  },
};

export const categoryVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

export const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: Math.min(i * 0.2, 1),
      duration: 0.5,
      ease: "easeOut",
    },
  }),
};

export const useAnimationLogic = () => {
  const [showVideo, setShowVideo] = useState(false);
  const categoryControls = useAnimation();
  const cardControls = useAnimation();

  useEffect(() => {
    const fallbackTimer = setTimeout(() => {
      cardControls.set({ opacity: 1, y: 0 });
    }, 2000);

    return () => clearTimeout(fallbackTimer);
  }, [cardControls]);

  const handleAnimationComplete = useCallback(() => {
    const timer1 = setTimeout(() => setShowVideo(true), 500);
    const timer2 = setTimeout(() => {
      categoryControls.start("visible");
      cardControls.start("visible");
    }, 100);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [categoryControls, cardControls]);

  return {
    showVideo,
    setShowVideo,
    categoryControls,
    cardControls,
    handleAnimationComplete,
  };
};
