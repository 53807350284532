const welcomePageVariants = {
  default: {
    selectedRoleplay: "cold_call_jimmy_easy",
    title: "Practice a cold call",
    description:
      "Skylar is the ultimate way to have better sales conversations. Practice with AI clients in different scenarios. Get feedback and get better.",
    introText: "",
    gradientBackground: false,
  },
  chrisVoss: {
    selectedRoleplay: "chris_voss",
    title: "Negotiate Using Chris Voss's Methods",
    description:
      "Practice a Real Negotiation with Skylar using Ruben Hassid's Scenario",
    introText: "",
    gradientBackground: true,
  },
  theSDRnewsletter: {
    selectedRoleplay: "cold_call_jimmy_easy",
    title: "Practice a Cold Call",
    description:
      "Press the PLAY button below to cold call a sales operations leader, selling Salesforce",
    introText: "The SDR Newsletter presents",
    gradientBackground: true,
  },
  pitchfolio: {
    displayName: "Unknown",
    email: "demo@example.com",
    selectedRoleplay: "cold_call_jimmy_easy",
    title: "Practice a Cold Call",
    description:
      "Press the PLAY button below to cold call a sales operations leader, selling Salesforce",
    introText: "Pitchfolio presents",
    gradientBackground: true,
  },
  gtm: {
    displayName: "Unknown",
    email: "demo@example.com",
    selectedRoleplay: "cold_call_jimmy_easy",
    title: "Practice a Cold Call",
    description:
      "Press the PLAY button below to cold call a sales operations leader, selling Salesforce",
    introText: "GTM Solutions Consulting presents",
    gradientBackground: true,
  },
  cfo: {
    displayName: "Unknown",
    email: "demo@example.com",
    selectedRoleplay: "cold_call_payhawk",
    title: "Practice a Cold Call",
    description:
      "Press the PLAY button below to cold call a finance professional",
    gradientBackground: true,
  },
  cs: {
    displayName: "Unknown",
    email: "demo@example.com",
    selectedRoleplay: "customer_service_demo",
    title: "Practice a Customer Service Call",
    description: "Start a simulation of a customer service call.",
    introText: "",
    gradientBackground: false,
  },
  // Add more variants here
};

export default welcomePageVariants;
