import React from "react";
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const ChatBox = ({ messages }) => {
  return (
    <MKBox
      p={2}
      borderRadius="lg"
      shadow="md"
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          <MKBox
            p={1}
            my={1}
            bgColor={message.actor === "user" ? "primary" : "secondary"}
            color="white"
            borderRadius="lg"
            maxWidth="70%"
            ml={message.actor === "user" ? "auto" : "0"}
          >
            <MKTypography variant="body2" color="white">
              {message.message}
            </MKTypography>
          </MKBox>
          <MKTypography
            px={1}
            variant="caption"
            color="primary"
            style={{
              display: "block",
              marginTop: "4px",
              textAlign: message.actor === "user" ? "right" : "left",
            }}
          >
            {message.actor === "user" ? "You" : "Prospect"}
          </MKTypography>
        </React.Fragment>
      ))}
    </MKBox>
  );
};

ChatBox.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      actor: PropTypes.oneOf(["user", "assistant"]).isRequired,
      message: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ChatBox;
