import React, { forwardRef, useImperativeHandle, useState } from "react";
import MKModal from "components/MKModal";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SignUpForm from "components/SignUpForm";

const SignUpModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  return (
    <MKModal open={open} handleClose={() => setOpen(false)}>
      <MKBox
        variant="gradient"
        bgColor="secondary"
        borderRadius="lg"
        coloredShadow="secondary"
        mx={2}
        p={2}
        mb={3}
        textAlign="center"
      >
        <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Sign up for complete access to Skylar
        </MKTypography>
      </MKBox>
      <MKBox textAlign="center" mx={2} mb={2}>
        <MKTypography
          variant="subtitle2"
          fontWeight="regular"
          color="text"
          mt={1}
        >
          Get access to multiple roleplays, detailed assessments, recordings and
          more!
        </MKTypography>
      </MKBox>
      <SignUpForm onSignUpSuccess={() => setOpen(false)} />
    </MKModal>
  );
});

SignUpModal.displayName = "SignUpModal";

export default SignUpModal;
