import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import breakpoints from "assets/theme/base/breakpoints";

function SelectTabs({ tabValue, handleSetTabValue, dataReady }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  console.log("dataReady", dataReady);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <AppBar position="static">
        <Tabs
          orientation={tabsOrientation}
          value={tabValue}
          onChange={handleSetTabValue}
        >
          <Tab
            label="Assessment"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                assignment
              </Icon>
            }
          />
          <Tab
            label="Recording & Analysis"
            icon={
              <Icon fontSize="small" sx={{ mt: -0.25 }}>
                description
              </Icon>
            }
          />
        </Tabs>
      </AppBar>
    </Grid>
  );
}

SelectTabs.propTypes = {
  tabValue: PropTypes.number.isRequired,
  handleSetTabValue: PropTypes.func.isRequired,
  dataReady: PropTypes.bool,
};

export default SelectTabs;
