export const ORG_DOMAIN_MAPPING = {
  "multiverse.io": "org_multiverse",
  "fisglobal.com": "org_fis",
  "payproglobal.com": "org_paypro",
  "getskylar.com": "org_skylar",
  "zimbra.com": "org_zimbra",
};

export const SPECIFIC_EMAIL_MAPPING = {
  "example@specific.com": "org_specific",
  "jack@entr-tech.com": "org_entr_tech",
  "fabdisamed@gmail.com": "org_entr_tech",
  "gulled_mohamed2024@outlook.com": "org_entr_tech",
  "mrism002@gmail.com": "org_entr_tech",
  "ngonidzashemurombe@gmail.com": "org_entr_tech",
  "omar08374@gmail.com": "org_entr_tech",
  "umarjeewa1@gmail.com": "org_entr_tech",
  "danielpetcuiulian@gmail.com": "org_entr_tech",
  "haseebminhas848@gmail.com": "org_entr_tech",
  "kierannagarajah9@gmail.com": "org_entr_tech",
  "lincolnimade@gmail.com": "org_entr_tech",
  "m.elmi2005@gmail.com": "org_entr_tech",
  "test@entr-tech.com": "org_entr_tech",
  "aaron.s.deo@hotmail.co.uk": "org_entr_tech_august",
  "andre.meehan@gmail.com": "org_entr_tech_august",
  "danmur94@icloud.com": "org_entr_tech_august",
  "emmanuelyankson@gmail.com": "org_entr_tech_august",
  "ephraimofori@icloud.com": "org_entr_tech_august",
  "hamzaabdulkadir.m@gmail.com": "org_entr_tech_august",
  "jorsek457@hotmail.com": "org_entr_tech_august",
  "julian_3000s@outlook.com": "org_entr_tech_august",
  "mzfahem11@gmail.com": "org_entr_tech_august",
  "sofiemsgina101@gmail.com": "org_entr_tech_august",
  "Khalid_hassan2009@live.com": "org_entr_tech_august",
  "abidtejani@gmail.com": "org_entr_tech_october",
  "aarushi19999@yahoo.co.uk": "org_entr_tech_october",
  "Caneepan@gmail.com": "org_entr_tech_october",
  "ebado02@outlook.com": "org_entr_tech_october",
  "evan1910@gmail.com": "org_entr_tech_october",
  "favourogundele@yahoo.com": "org_entr_tech_october",
  "gazzakon419@gmail.com": "org_entr_tech_october",
  "hamsenur7@gmail.com": "org_entr_tech_october",
  "liban.o@outlook.com": "org_entr_tech_october",
  "lojanan2703@gmail.com": "org_entr_tech_october",
  "mundusjones@gmail.com": "org_entr_tech_october",
  "sila-beyaz@hotmail.com": "org_entr_tech_october",
  "skye.chase27@outlook.com": "org_entr_tech_october",
  "sonnywatkinson@gmail.com": "org_entr_tech_october",
  "wasim.chohan98@gmail.com": "org_entr_tech_october",
  "zaktharani@gmail.com": "org_entr_tech_october",
  "imobutcher00@gmail.com": "org_entr_tech_october",
  "suhailahmed241@gmail.com": "org_entr_tech_october",
};

// Add new mapping for org settings
export const ORG_SETTINGS = {
  org_multiverse: {
    authLevel: "multiverse",
    defaultRoleplay: "cold_call_multiverse_cdo",
  },
  org_skylar: {
    authLevel: "skylar",
    defaultRoleplay: "cold_call_skylar_demo",
  },
  org_paypro: {
    authLevel: "PayPro Global",
    defaultRoleplay: "cold_call_paypro_demo",
  },
  org_fis: {
    authLevel: "FIS Global",
    defaultRoleplay: "cold_call_fis_demo",
  },
  org_entr_tech: {
    authLevel: "Entr Tech",
    defaultRoleplay: "cold_call_steve",
  },
  org_entr_tech_august: {
    authLevel: "Entr Tech",
    defaultRoleplay: "cold_call_jimmy_easy",
  },
  org_entr_tech_october: {
    authLevel: "Entr Tech",
    defaultRoleplay: "cold_call_steve",
  },
};
