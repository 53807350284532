import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

const ErrorDisplay = ({ errorMessage }) => {
  const [anchorElError, setAnchorElError] = useState(null);
  const [anchorElErrorDetails, setAnchorElErrorDetails] = useState(null);

  return (
    <MKBox>
      <MKBox
        variant="gradient"
        bgColor="error"
        coloredShadow="error"
        borderRadius="lg"
        p={2}
        mx={2}
        mt={-3}
      >
        <MKTypography
          variant="h3"
          color="white"
          aria-owns={anchorElError ? "mouse-over-popover-error" : undefined}
          aria-haspopup="true"
          onMouseEnter={(event) => setAnchorElError(event.currentTarget)}
          onMouseLeave={() => setAnchorElError(null)}
        >
          We couldn&apos;t assess your conversation
        </MKTypography>
        <Popover
          id="mouse-over-popover-error"
          sx={{
            pointerEvents: "none",
          }}
          open={Boolean(anchorElError)}
          anchorEl={anchorElError}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => setAnchorElError(null)}
          disableRestoreFocus
          slotProps={{
            paper: {
              sx: {
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                marginTop: "10px",
              },
            },
          }}
        >
          <MKBox p={2} maxWidth={300}>
            <MKTypography variant="body2" color="text">
              This error occurs when we are unable to process your conversation.
              It could be due to various reasons such as insufficient data,
              technical issues, or an unexpected format of the conversation.
            </MKTypography>
          </MKBox>
        </Popover>
      </MKBox>
      <MKBox p={3} mb={3}>
        <MKTypography
          variant="body2"
          color="error"
          mb={1}
          aria-owns={
            anchorElErrorDetails
              ? "mouse-over-popover-error-details"
              : undefined
          }
          aria-haspopup="true"
          onMouseEnter={(event) => setAnchorElErrorDetails(event.currentTarget)}
          onMouseLeave={() => setAnchorElErrorDetails(null)}
        >
          {errorMessage}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
};

ErrorDisplay.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorDisplay;
