/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// Material Dashboard 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import MKBadge from "components/MKBadge";

// Images
import team2 from "assets/images/avatars/jane.png";
import team3 from "assets/images/avatars/peter.png";
import team4 from "assets/images/avatars/taylor.png";

export default function data() {
  const Author = ({ image, name, email }) => (
    <MKBox display="flex" alignItems="center" lineHeight={1}>
      <MKAvatar src={image} name={name} size="sm" />
      <MKBox ml={2} lineHeight={1}>
        <MKTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MKTypography>
        <MKTypography variant="caption">{email}</MKTypography>
      </MKBox>
    </MKBox>
  );

  const Job = ({ title, description }) => (
    <MKBox lineHeight={1} textAlign="left">
      <MKTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MKTypography>
      <MKTypography variant="caption">{description}</MKTypography>
    </MKBox>
  );

  return {
    columns: [
      { Header: "name", accessor: "function", align: "left" },
      { Header: "date", accessor: "employed", align: "center" },
      { Header: "score", accessor: "action", align: "center" },
    ],

    rows: [
      {
        author: (
          <Author
            image={team2}
            name="John Michael"
            email="john@creative-tim.com"
          />
        ),
        function: <Job title="Manager" description="Organization" />,
        status: (
          <MKBox ml={-1}>
            <MKBadge
              badgeContent="online"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MKBox>
        ),
        employed: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            23/04/18
          </MKTypography>
        ),
        action: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MKTypography>
        ),
      },
      {
        author: (
          <Author
            image={team3}
            name="Alexa Liras"
            email="alexa@creative-tim.com"
          />
        ),
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MKBox ml={-1}>
            <MKBadge
              badgeContent="offline"
              color="dark"
              variant="gradient"
              size="sm"
            />
          </MKBox>
        ),
        employed: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            11/01/19
          </MKTypography>
        ),
        action: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MKTypography>
        ),
      },
      {
        author: (
          <Author
            image={team4}
            name="Laurent Perrier"
            email="laurent@creative-tim.com"
          />
        ),
        function: <Job title="Executive" description="Projects" />,
        status: (
          <MKBox ml={-1}>
            <MKBadge
              badgeContent="online"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MKBox>
        ),
        employed: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            19/09/17
          </MKTypography>
        ),
        action: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MKTypography>
        ),
      },
      {
        author: (
          <Author
            image={team3}
            name="Michael Levi"
            email="michael@creative-tim.com"
          />
        ),
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MKBox ml={-1}>
            <MKBadge
              badgeContent="online"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MKBox>
        ),
        employed: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            24/12/08
          </MKTypography>
        ),
        action: (
          <MKTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MKTypography>
        ),
      },
    ],
  };
}
