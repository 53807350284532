// SocketService.js
import io from "socket.io-client";

class SocketService {
  static instance = null;

  static getInstance() {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  constructor() {
    if (!SocketService.instance) {
      this.socket = io(process.env.REACT_APP_URL, {
        transports: ["websocket"],
      });
      this.isListeningForAudioChunks = false;
      this.listeners = {};
      this.registerDefaultEvents();
    }
    return SocketService.instance;
  }

  registerDefaultEvents() {
    // Log when successfully connected
    this.socket.on("connect", () =>
      console.log("Socket connected with id:", this.socket.id),
    );

    // Existing event listeners with added logging for each
    this.socket.on("connect_error", (error) => {
      console.log("Connect error event triggered");
      this.handleConnectionIssue(error);
    });
    this.socket.on("connect_timeout", (timeout) => {
      console.log("Connect timeout event triggered, timeout:", timeout);
      this.handleConnectionIssue(
        new Error(`Connection timeout after ${timeout}ms`),
      );
    });
    this.socket.on("reconnect_failed", () => {
      console.log("Reconnect failed event triggered");
      this.handleConnectionIssue(new Error("Reconnect failed"));
    });

    // Log when the socket is disconnected
    this.socket.on("disconnect", (reason) =>
      console.log("Socket disconnected, reason:", reason),
    );
  }

  handleConnectionIssue(error) {
    console.error("Connection issue:", error.message);
    console.error("Error stack:", error.stack || "No stack trace available");
    const event = new CustomEvent("socketError", { detail: error });
    window.dispatchEvent(event);
  }

  connect() {
    return new Promise((resolve, reject) => {
      if (!this.socket.connected) {
        console.log("Initiating connection...");

        // Listen once for the 'connect' event to resolve the promise with the socket ID
        this.socket.once("connect", () => {
          resolve(this.socket.id); // Resolve with the socket ID
        });

        // Listen once for the 'connect_error' event to reject the promise
        this.socket.once("connect_error", (error) => {
          console.error("Connection error event received:", error);
          reject(new Error("Connection failed due to error: " + error.message));
        });

        try {
          // Attempt to connect
          this.socket.connect();
          console.debug(
            "Attempting to connect... Environment URL:",
            process.env.REACT_APP_URL,
          );
        } catch (error) {
          // Catch synchronous errors thrown by `this.socket.connect()`
          console.error("Error thrown during socket.connect() call:", error);
          this.handleConnectionIssue(error);
          reject(error);
        }
      } else {
        console.log("Socket already connected. Socket ID:", this.socket.id);
        resolve(this.socket.id); // Resolve with the socket ID if already connected
      }
    });
  }

  disconnect() {
    this.socket.disconnect();
    console.log("we have disconnected");
  }

  //Emitters

  sendRestart(feedbackMessage) {
    this.socket.emit("restart", feedbackMessage);
  }

  startGreeting() {
    this.socket.emit("startGreeting");
  }

  startMicrophoneStream(transcribeConfig) {
    this.socket.emit("startMicrophoneStream", transcribeConfig);
  }

  sendBinaryAudioData(audioBlob) {
    this.socket.emit("binaryAudioData", audioBlob);
  }

  endMicrophoneStream() {
    this.socket.emit("endMicrophoneStream");
  }

  // Listeners

  addListener(eventName, listener) {
    if (this.listeners[eventName]) {
      console.error(`Already listening for ${eventName}.`);
      return;
    }
    this.socket.on(eventName, listener);
    this.listeners[eventName] = true;
  }

  removeListener(eventName) {
    this.socket.off(eventName);
    delete this.listeners[eventName];
  }

  startListeningForAudioChunk(onSynthesisedAudioChunk) {
    this.addListener("synthesisedAudioChunk", onSynthesisedAudioChunk);
  }

  stopListeningForAudioChunk() {
    this.removeListener("synthesisedAudioChunk");
  }

  startListeningForAudioEnd(onSynthesisedAudioEnd) {
    this.addListener("synthesisedAudioFinished", onSynthesisedAudioEnd);
  }

  stopListeningForAudioEnd() {
    this.removeListener("synthesisedAudioFinished");
  }

  startListeningForTranscriptionData(onSpeechData) {
    this.addListener("speechData", onSpeechData); //TODO rename to Transcribed Speech
  }

  stopListeningForTranscriptionData() {
    this.removeListener("speechData");
  }

  startListeningForEndOfTranscription(onEndOfTranscription) {
    this.addListener("endOfTranscription", onEndOfTranscription);
  }

  stopListeningForEndOfTranscription() {
    this.removeListener("endOfTranscription");
  }

  startListeningForTranscriptionError(onTranscribedSpeechError) {
    this.addListener("transcribedSpeechError", onTranscribedSpeechError);
  }

  stopListeningForTranscriptionError() {
    this.removeListener("transcribedSpeechError");
  }

  startListeningForChatResponse(onChatResponse) {
    this.addListener("chatResponse", onChatResponse);
  }

  stopListeningForChatResponse() {
    this.removeListener("chatResponse");
  }
}

export default SocketService;
