import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/firebase/AuthContext";

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    const signOutAndRedirect = async () => {
      await signOut();
      navigate("/");
    };

    signOutAndRedirect();
  }, [navigate]);

  return null;
};

export default SignOut;
