// CallViewSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const callViewSlice = createSlice({
  name: "callViewState",
  initialState: {
    callState: "waiting",
    isLastChunkReceived: false,
  },
  reducers: {
    setCallState: (state, action) => {
      state.callState = action.payload;
    },
    setIsLastChunkReceived: (state, action) => {
      // Add a reducer to manage isLastChunkReceived
      state.isLastChunkReceived = action.payload;
    },
  },
});

export const { setCallState, setIsLastChunkReceived } = callViewSlice.actions;

export default callViewSlice.reducer;
