import React from "react";
import { motion } from "framer-motion";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import MKButton from "components/MKButton";

const MotionGrid = motion(Grid);

function CompletionMessage() {
  const navigate = useNavigate();

  return (
    <MotionGrid
      container
      item
      xs={12}
      lg={7}
      sx={{ mx: "auto" }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <MKBox width="100%" textAlign="center" p={3}>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 0.2,
          }}
        >
          <CheckCircleIcon
            sx={{
              fontSize: 80,
              color: "success.main",
              mb: 3,
            }}
          />
        </motion.div>

        <MKTypography
          variant="h3"
          fontWeight="bold"
          mb={2}
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          Setup Complete!
        </MKTypography>

        <MKTypography
          variant="body1"
          color="text"
          mb={4}
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          Your AI assistant has been configured successfully. You can now start
          using it in your conversations.
        </MKTypography>

        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          mt={2}
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <MKButton
            variant="contained"
            color="dark"
            size="large"
            component={motion.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/my-simulations")}
          >
            Try it out!
          </MKButton>
        </Grid>
      </MKBox>
    </MotionGrid>
  );
}

export default CompletionMessage;
