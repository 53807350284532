import { functions } from "./firebase/firebase";
import { httpsCallable } from "firebase/functions";

const sendErrorEmailFunction = httpsCallable(functions, "sendErrorEmail");

export const sendErrorEmail = async (error, errorInfo, userEmail) => {
  try {
    console.log("Attempting to send error email...");
    await sendErrorEmailFunction({
      error: error.toString(),
      errorInfo: JSON.stringify(errorInfo),
      userEmail: userEmail,
    });
    console.log("Error report email sent successfully.");
  } catch (e) {
    console.error("Failed to send error report email:", e);
  }
};
