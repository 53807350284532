import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "services/firebase/firebase";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";
import Switch from "@mui/material/Switch";
import Slide from "@mui/material/Slide";
import { isValidEmail } from "utils/validation";

const SignUpForm = ({ onSignUpSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const buttonRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [popoverMessage, setPopoverMessage] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (!email.trim() || !isValidEmail(email)) {
      setPopoverMessage("Please enter a valid email address.");
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
      return;
    }

    if (password.length < 6) {
      setPopoverMessage("Password should be at least 6 characters.");
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      onSignUpSuccess();
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        // Attempt to sign in since the email is already in use
        try {
          await signInWithEmailAndPassword(auth, email, password);
          onSignUpSuccess();
        } catch (signInError) {
          console.error("Error signing in", signInError);
          // Handle sign-in errors (e.g., wrong password)
          let userFriendlyMessage =
            "An unexpected error occurred. Please try again.";
          if (signInError.code === "auth/wrong-password") {
            userFriendlyMessage = "Incorrect password. Please try again.";
          } else if (signInError.code === "auth/user-disabled") {
            userFriendlyMessage =
              "This account has been disabled. Please contact the administrators.";
          }
          setPopoverMessage(userFriendlyMessage);
          setShowPopover(true);
          setTimeout(() => setShowPopover(false), 1200); // Hide after 1 second
        }
      } else {
        // Handle other sign-up errors
        console.error("Error signing up with email and password", error);
        let userFriendlyMessage =
          "An unexpected error occurred. Please try again.";
        if (error.code === "auth/weak-password") {
          userFriendlyMessage = "Password should be at least 6 characters.";
        } else if (error.code === "auth/invalid-email") {
          userFriendlyMessage = "Please enter a valid email address.";
        }
        setPopoverMessage(userFriendlyMessage);
        setShowPopover(true);
        setTimeout(() => setShowPopover(false), 1200); // Hide after 1 second
      }
    }
  };

  const handlePasswordReset = async () => {
    if (!email.trim() || !isValidEmail(email)) {
      setPopoverMessage("Please enter a valid email address.");
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setPopoverMessage("Password reset email sent! Please check your inbox.");
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
    } catch (error) {
      console.error("Failed to send password reset email", error);
      setPopoverMessage(
        "Failed to send password reset email. Please try again.",
      );
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
    }
  };

  return (
    <>
      <MKBox component="form" role="form">
        <MKBox mb={2}>
          <MKInput
            type="email"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
        </MKBox>
        <MKBox mb={2}>
          <MKInput
            type="password"
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
        </MKBox>
        <MKBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MKTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MKTypography>
        </MKBox>
        <MKBox mt={4} mb={1}>
          <MKButton
            variant="gradient"
            color="secondary"
            fullWidth
            onClick={handleSignUp}
            ref={buttonRef}
          >
            sign up / sign in
          </MKButton>
        </MKBox>
        <MKBox mt={3} mb={1} textAlign="center">
          <MKTypography
            variant="button"
            color="info"
            onClick={handlePasswordReset}
            style={{ cursor: "pointer" }} // Add pointer cursor to indicate clickable
          >
            Forgot your password?
          </MKTypography>
        </MKBox>
        <Slide direction="down" in={showPopover} timeout={200} unmountOnExit>
          <MKBox position="absolute" top="0.5rem" left={0} width="100%">
            <MKAlert
              width="25%"
              mx="auto"
              color="error"
              sx={{
                minHeight: "2.5rem !important",
                py: 1,
                justifyContent: "center",
              }}
            >
              <MKTypography variant="body2" color="white" fontWeight="regular">
                {popoverMessage}
              </MKTypography>
            </MKAlert>
          </MKBox>
        </Slide>
      </MKBox>
    </>
  );
};

SignUpForm.propTypes = {
  onSignUpSuccess: PropTypes.func,
};

export default SignUpForm;
