import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/HttpService";

export const analyzeWebsite = createAsyncThunk(
  "websiteAnalysis/analyzeWebsite",
  async (websiteUrl, { dispatch, rejectWithValue }) => {
    try {
      const stream = await httpService.performPostRequestStream(
        "api/persona/generate",
        {
          website_url: websiteUrl,
        },
      );

      const reader = stream.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      let isReading = true;

      while (isReading) {
        const { done, value } = await reader.read();
        if (done) {
          isReading = false;
          console.log("Stream reading completed.");
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");

        // Process all complete lines
        for (let i = 0; i < lines.length - 1; i++) {
          const line = lines[i].trim();
          if (line) {
            try {
              const data = JSON.parse(line);
              if (data.status === "initiated") {
                dispatch(websiteAnalysisSlice.actions.setInitiated());
              } else if (data.status === "completed") {
                dispatch(
                  websiteAnalysisSlice.actions.setAnalysisData(data.data),
                );
              }
            } catch (parseError) {
              console.error("Error parsing line:", line, "Error:", parseError);
            }
          }
        }

        buffer = lines[lines.length - 1];
      }

      // Process any remaining data
      if (buffer.trim()) {
        const data = JSON.parse(buffer);
        if (data.status === "completed") {
          dispatch(websiteAnalysisSlice.actions.setAnalysisData(data.data));
        }
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const websiteAnalysisSlice = createSlice({
  name: "websiteAnalysis",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'initiated' | 'succeeded' | 'failed'
    error: null,
    analysisData: null,
  },
  reducers: {
    setAnalysisData: (state, action) => {
      state.status = "succeeded";
      state.analysisData = action.payload;
    },
    setInitiated: (state) => {
      state.status = "initiated";
    },
    resetWebsiteAnalysis: (state) => {
      state.analysisData = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(analyzeWebsite.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(analyzeWebsite.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetWebsiteAnalysis } = websiteAnalysisSlice.actions;
export default websiteAnalysisSlice.reducer;
