import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Vapi from "@vapi-ai/web";
// import MockVapi from "_mocks_/MockVapi";
import CallViewCard from "components/CallViewCard";
import useGreeting from "hooks/useGreeting/useGreeting";
import { setupVapiEventListeners } from "utils/vapiEventListeners";
import { setCallState } from "../../features/callView/callViewSlice";
import roleplayConfig from "config/roleplayConfig";
import { useAfterCallViewNavigation } from "utils/afterCallViewNavigation";
import { useAfterCallViewFeedback } from "utils/afterCallViewFeedback";
import UserOnboardingModal from "pages/NewView/UserOnboardingModal";
import {
  fetchRoleplayConfig,
  resetRoleplayConfigDynamic,
  resetUpdateStatus,
} from "features/roleplayConfigDynamic/roleplayConfigDynamicSlice";
import LoginModal from "./LoginModal";
import { AuthContext } from "services/firebase/AuthContext";
import DashboardLayout from "layouts/DashboardLayout";
import ErrorModal from "components/BaseModal/ErrorModal";
import { sendErrorEmail } from "services/errorService";
import { useMaterialUIController } from "context/materialUIController";
import { setLoading } from "features/loading/loadingSlice";
import useNavigationPrompt from "hooks/useNavigationPrompt";

const NewView = () => {
  const userName = useSelector((state) => state.user.displayName);
  const userEmail = useSelector((state) => state.user.email);

  const totalRoleplaysToday = useSelector(
    (state) => state.user.totalRoleplaysToday,
  );
  const orgId = useSelector((state) => state.user.orgId);

  const selectedRoleplayId = useSelector(
    (state) => state.user.selectedRoleplay,
  );

  const dispatch = useDispatch();

  // Add a check for selectedRoleplay
  const selectedRoleplay = roleplayConfig[selectedRoleplayId] || {};
  console.log("selectedRoleplay", selectedRoleplay);
  const {
    firstCallViewTone = null,
    secondCallViewTone = null,
    courseId = null,
  } = selectedRoleplay;

  const customPersona = useSelector((state) => state.user.customPersona);
  // Backend Request Data
  const roleplayConfigDynamicData = useSelector(
    (state) => state.roleplayConfigDynamic.data,
  );
  const roleplayConfigDynamicStatus = useSelector(
    (state) => state.roleplayConfigDynamic.status,
  );
  const company = roleplayConfigDynamicData.company;
  const companyDetails = roleplayConfigDynamicData.company_details;
  const level = roleplayConfigDynamicData.level;
  const initialConversationId = roleplayConfigDynamicData.conversation_id;
  const vapiAssistantId = roleplayConfigDynamicData.vapi_id;
  const voiceUrl = roleplayConfigDynamicData.voice_url;
  console.log("vapiAssistantId", vapiAssistantId);

  const currentConversationId = useSelector(
    (state) => state.roleplayConfigDynamic.currentConversationId,
  );

  const [isLoading, setIsLoading] = useState(true);

  const vapiRef = useRef(null);

  const [, dispatchMaterialUIController] = useMaterialUIController();

  const initializeComponent = () => {
    setIsLoading(true);
    dispatch(setLoading(true));
    vapiRef.current = new Vapi(process.env.REACT_APP_VAPI_KEY);
    // vapiRef.current = new MockVapi();
    setupVapiEventListeners(
      vapiRef,
      stopGreeting,
      setIsExerciseEndModalOpen,
      setCallHasStarted,
      dispatchMaterialUIController,
      volumeLevel,
      navigate,
    );
  };

  const { authenticatedCurrentUser, finishConversation } =
    useContext(AuthContext);
  // New useEffect hook to dispatch fetchRoleplayConfig when userName updates and is not "Unknown"
  useEffect(() => {
    if (
      userName !== "Unknown" &&
      roleplayConfigDynamicStatus === "idle" &&
      courseId
    ) {
      let premiumCharacter = false;
      if (totalRoleplaysToday < 3) {
        premiumCharacter = true;
      }

      const configParams = {
        email: userEmail,
        name: userName,
        courseId: courseId,
        premiumCharacter: premiumCharacter,
        orgId: orgId,
      };

      if (selectedRoleplayId === "custom_bot") {
        // Validate that we have all required custom bot data
        if (
          !customPersona?.avatarUrl ||
          !customPersona?.clientCompanyInfo.name ||
          !customPersona?.sellerCompanyName
        ) {
          console.error("Missing required custom bot data:", {
            avatarUrl: customPersona?.avatarUrl,
            clientCompanyName: customPersona?.clientCompanyInfo?.name,
            sellerCompanyName: customPersona?.sellerCompanyName,
          });
          console.error("Missing required custom bot data:", customPersona);
          return; // Exit early if we're missing required data
        }

        Object.assign(configParams, {
          isTransient: true,
          avatarUrl: customPersona.avatarUrl,
          clientName: customPersona.name,
          sellerCompanyName: customPersona.sellerCompanyName,
        });
      } else {
        configParams.isTransient = false;
      }

      dispatch(fetchRoleplayConfig(configParams));
    }
  }, [userName, dispatch, courseId]); // Add any other dependencies if necessary

  //control the Loading screen based on whether backend request had returned
  useEffect(() => {
    if (roleplayConfigDynamicStatus === "succeeded") {
      setIsLoading(false);
      dispatch(setLoading(false));
    } else {
      setIsLoading(true);
      dispatch(setLoading(true));
    }
  }, [roleplayConfigDynamicStatus]);

  //On load of the page, we initialize the component
  useEffect(() => {
    initializeComponent();

    return () => {
      dispatch(setCallState("waiting"));
      dispatch(resetRoleplayConfigDynamic());
      dispatch(resetUpdateStatus());
      vapiRef.current.stop();
      console.log("Component unmounting, disconnecting Vapi.");
    };
  }, []);

  //greeting audio
  const [callHasStarted, setCallHasStarted] = useState(false);
  const { playGreeting, stopGreeting } = useGreeting(
    firstCallViewTone,
    secondCallViewTone,
    callHasStarted,
  );

  //end of conversation
  const [isExerciseEndModalOpen, setIsExerciseEndModalOpen] = useState(false);
  const goToAssessment = useAfterCallViewNavigation(setIsExerciseEndModalOpen);
  const processFeedback = useAfterCallViewFeedback();

  //finish conversation - atm, we only finish if have seen the Modal
  useEffect(() => {
    if (isExerciseEndModalOpen && authenticatedCurrentUser) {
      finishConversation(authenticatedCurrentUser.uid, currentConversationId);
      //TODO fix immediatelys
    }
  }, [isExerciseEndModalOpen]);

  const navigate = useNavigate();
  const resetComponent = () => {
    navigate("/redirect");
  };

  const handleRestart = (createModal) => {
    if (createModal) {
      resetComponent();
    } else {
      setIsExerciseEndModalOpen(false);
      resetComponent();
    }
  };

  useNavigationPrompt(callHasStarted);

  //set up call state
  // const [isExerciseEnd, setIsExerciseEnd] = useState(false);
  // const currentCallState = useSelector(
  //   (state) => state.callViewState.callState
  // );
  // const determineCallState = (
  //   isRecording,
  //   isPlaying,
  //   isGreetingPlaying,
  //   currentCallState
  // ) => {
  //   if (isRecording) {
  //     console.log("Call State: User is speaking.");
  //     return "user_speaking";
  //   } else if (isGreetingPlaying) {
  //     console.log("Call State: Greeting is playing.");
  //     return "greeting_playing";
  //   } else if (isPlaying) {
  //     console.log("Call State: User is listening.");
  //     return "user_listening";
  //   } else {
  //     if (currentCallState === "user_speaking") {
  //       console.log("Call State: Waiting for response.");
  //       return "waiting_for_response";
  //     } else if (currentCallState === "user_listening") {
  //       console.log(
  //         "Call State: User is now waiting for their turn to speak. Starting..."
  //       );
  //       if (!isExerciseEnd) {
  //         // onStart();
  //       } else {
  //         // onStop();
  //         console.log("End of exercise. Opening modal...");
  //         setIsExerciseEndModalOpen(true);
  //         setIsExerciseEnd(false);
  //         return "end_of_exercise";
  //       }
  //     } else {
  //       console.log("Call State: Waiting.");
  //       return "waiting";
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const newCallState = determineCallState(
  //     isRecording,
  //     isPlaying,
  //     isGreetingPlaying,
  //     currentCallState
  //   );
  //   dispatch(setCallState(newCallState));
  // }, [isRecording, isPlaying, isGreetingPlaying, isExerciseEnd, dispatch]);

  const volumeLevel = useRef(0);

  return (
    <DashboardLayout>
      <ErrorBoundary
        FallbackComponent={ErrorModal}
        onReset={() => {
          window.location.href = "/my-simulations";
        }}
        onError={(error, errorInfo) => {
          console.log("ErrorBoundary activated:", error, errorInfo);
          sendErrorEmail(error, errorInfo, userEmail || "Unknown");
        }}
      >
        {selectedRoleplay && courseId ? (
          <>
            <CallViewCard
              callHasStarted={callHasStarted}
              handleRestart={handleRestart}
              isExerciseEndModalOpen={isExerciseEndModalOpen}
              goToAssessment={goToAssessment}
              processFeedback={processFeedback}
              volumeLevel={volumeLevel}
            />
            {userName === "Unknown" ? (
              <LoginModal />
            ) : (
              !isLoading && (
                <UserOnboardingModal
                  displayName={userName}
                  playGreeting={playGreeting}
                  company={company}
                  companyDetails={companyDetails}
                  level={level}
                  vapiRef={vapiRef}
                  vapiAssistantId={vapiAssistantId}
                  initialConversationId={initialConversationId}
                  voiceUrl={voiceUrl}
                  selectedRoleplay={selectedRoleplayId}
                />
              )
            )}
          </>
        ) : (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh / 3)", // Adjusted to be 1/3 away from the top
              marginTop: "calc(100vh / 3)", // Added margin to position it correctly
            }}
          >
            <p className="loading-text">Loading Roleplay Data...</p>
          </div>
        )}
      </ErrorBoundary>
    </DashboardLayout>
  );
};

export default NewView;
