import React from "react";
import { useModal } from "context/modalContext";
import BaseModal from "./BaseModal";

const ConfirmationModal = () => {
  const { modalProps, closeModal } = useModal();

  if (!modalProps.isOpen) return null;

  return (
    <BaseModal
      isOpen={modalProps.isOpen}
      title={modalProps.title}
      onClose={closeModal}
      actions={[
        { label: "No", onClick: modalProps.onCancel || closeModal },
        {
          label: "Yes",
          onClick: () => {
            if (modalProps.onConfirm) {
              modalProps.onConfirm();
            }
            closeModal();
          },
        },
      ]}
    >
      {modalProps.text_in_modal}
    </BaseModal>
  );
};

export default ConfirmationModal;
