import React, { useState } from "react";
import PropTypes from "prop-types";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

import TextSubmitModal from "./TextSubmitModal";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";

const FeedbackWithTextSubmitModal = (props) => {
  const [feedbackApproval, setFeedbackApproval] = useState(null); // Use feedbackApproval
  const handleFeedbackClick = (approval) => {
    setFeedbackApproval(approval); // Adjust to use feedbackApproval
  };

  const modifiedOnClose = (inputValues) => {
    // Include feedbackApproval in the data passed to onClose
    const modifiedInputValues = {
      ...inputValues,
      feedbackApproval, // Use feedbackApproval
    };
    props.onClose(modifiedInputValues);
  };

  // Prepare additional content with Like and Don't like buttons
  const additionalContent = (
    <MKBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="16px"
      mb={1}
      p={1}
    >
      <MKButton
        onClick={() => handleFeedbackClick(true)}
        color={feedbackApproval === true ? "primary" : "default"}
        size="large"
      >
        <ThumbUpIcon />
      </MKButton>
      <MKButton
        onClick={() => handleFeedbackClick(false)}
        color={feedbackApproval === false ? "primary" : "default"}
        size="large"
      >
        <ThumbDownIcon />
      </MKButton>
    </MKBox>
  );

  return (
    <TextSubmitModal
      {...props}
      onClose={modifiedOnClose}
      closeOnActionOnly={true}
      additionalContent={additionalContent}
    />
  );
};

FeedbackWithTextSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FeedbackWithTextSubmitModal;
