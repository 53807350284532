import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import WaveSurfer from "wavesurfer.js";

import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Grid from "@mui/material/Grid";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const WaveformPlayer = ({ url, timestamp }) => {
  const waveformRef = useRef(null);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // Add a new state to manage play/pause icon
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (url && waveformRef.current && !wavesurfer) {
      const wsInstance = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "violet",
        progressColor: "purple",
        cursorWidth: 0,
      });

      wsInstance.load(url);

      wsInstance.on("ready", () => {
        console.log("WaveSurfer is ready.");
        setDuration(wsInstance.getDuration()); // Set the duration from the loaded audio
      });

      wsInstance.on("error", (error) => {
        console.error("WaveSurfer error:", error);
      });

      wsInstance.on("audioprocess", () => {
        setCurrentTime(wsInstance.getCurrentTime()); // Update the current time during playback
      });

      wsInstance.on("finish", () => {
        setIsPlaying(false); // This will switch the button back to the PlayArrowIcon
      });

      setWavesurfer(wsInstance);
    }

    return () => wavesurfer && wavesurfer.destroy();
  }, [url]); // This useEffect depends on the `url` prop

  useEffect(() => {
    if (wavesurfer && timestamp) {
      const seconds = convertTimestampToSeconds(timestamp);
      const duration = wavesurfer.getDuration();
      if (isFinite(seconds) && duration > 0) {
        const seekPosition = Math.min(seconds / duration, 1);
        wavesurfer.seekTo(seekPosition);
        setCurrentTime(seconds);
      } else {
        console.warn("Invalid timestamp or duration:", {
          timestamp,
          seconds,
          duration,
        });
      }
    }
  }, [timestamp, wavesurfer]);

  const convertTimestampToSeconds = (timestamp) => {
    if (typeof timestamp !== "string" || !timestamp.includes(":")) {
      console.warn("Invalid timestamp format:", timestamp);
      return 0;
    }
    const [minutes, seconds] = timestamp.split(":").map(Number);
    return isFinite(minutes) && isFinite(seconds) ? minutes * 60 + seconds : 0;
  };

  const handlePlayPause = () => {
    if (wavesurfer) {
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause();
        setIsPlaying(false);
      } else {
        wavesurfer.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <>
      <MKBox
        display="flex"
        alignItems="center"
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <MKButton
          onClick={handlePlayPause}
          variant="contained"
          color="primary"
          size="large"
          iconOnly
          circular
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </MKButton>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MKBox id="waveform" ref={waveformRef} style={{ flex: 1 }}></MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              mt={-2}
            >
              <MKTypography variant="caption" color="textSecondary">
                {formatTime(currentTime)}/
              </MKTypography>
              <MKTypography variant="caption" color="textSecondary">
                {formatTime(duration)}
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
};

WaveformPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
};

export default WaveformPlayer;
