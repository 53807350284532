import { useSelector } from "react-redux";
import httpService from "services/HttpService";

export const useAfterCallViewFeedback = () => {
  // Use useSelector to access the conversationId from the Redux store
  const conversationId = useSelector(
    (state) => state.roleplayConfigDynamic.currentConversationId,
  );

  const processFeedback = (feedback) => {
    if (
      !feedback ||
      (feedback.feedbackApproval === null && feedback.feedbackText === "")
    ) {
      console.log("No feedback provided. Exiting function.");
      return;
    }

    console.debug("Feedback is:", feedback, conversationId);
    // Include conversationId in the data sent to the backend
    const data = {
      feedback,
      conversationId: conversationId,
    };

    httpService
      .performPostRequest("submit_feedback", data)
      .then((response) => {
        console.log("Feedback submitted successfully:", response);
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error.message);
      });
  };

  return processFeedback;
};
