import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "features/loading/loadingSlice";

const RedirectPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));

    const timer = setTimeout(() => {
      dispatch(setLoading(false));
      navigate("/callview");
    }, 500);

    return () => {
      clearTimeout(timer);
      dispatch(setLoading(false));
    };
  }, [navigate, dispatch]);

  return null;
};

export default RedirectPage;
