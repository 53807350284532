import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Grid, Popover } from "@mui/material";
import Grow from "@mui/material/Grow";

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import NavigationButtons from "./NavigationButtons";
import ErrorDisplay from "./ErrorDisplay";

import { fetchTranscript, startAssessment } from "../../utils/AssesmentUtils";
import ProcessAssessmentText from "./ProcessText";
import { setRank } from "../../features/user/userSlice";
import { AuthContext } from "services/firebase/AuthContext";
import DashboardLayout from "layouts/DashboardLayout";
import SelectTabs from "components/SelectTabs";
import ConversationBox from "./ConversationBox";
import CongratsModal from "./CongratsModal";
import categoryDescriptions from "./CategoryDescriptions";

const Assessment = () => {
  const rank = useSelector((state) => state.user.rank);

  const dispatch = useDispatch();

  const increaseRank = () => {
    dispatch(setRank(rank + 1));
  };

  const { authenticatedCurrentUser, getCurrentConversationId } =
    useContext(AuthContext);

  const [assessmentResults, setAssessmentResults] = useState(null);
  const [isAssessmentComplete, setIsAssessmentComplete] = useState(false);

  //SelectTabs Logic
  const [selectedTab, setSelectedTab] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setSelectedTab(newValue);
  };

  //Transcript messages
  const [messages, setMessages] = useState([]);
  const [callRecordingUrl, setCallRecordingUrl] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [transcriptDataReady, setTranscriptDataReady] = useState(false);

  //loading messages
  const [messageIndex, setMessageIndex] = useState(0);

  const loadingMessages = [
    "Transcribing your conversation...",
    "Analyzing speech patterns...",
    "Generating insights...",
    "Preparing your assessment...",
    "Oh, that's interesting...",
    "Did you really say that?!?...",
    "No, we are joking, it's just taking us some time...",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [loadingMessages.length]);

  const [haveMadeBackendRequest, setHaveMadeBackendRequest] = useState(false);

  useEffect(() => {
    if (authenticatedCurrentUser && !haveMadeBackendRequest) {
      const handleDataReceived = (newData) => {
        if (newData.type === "transcript") {
          setMessages(newData.transcript);
          setCallRecordingUrl(newData.callRecordingUrl);
          setTranscriptDataReady(true);
        } else if (newData.type === "extractedData") {
          setExtractedData(newData.data);
        }
      };

      const handleAssessmentData = (newData) => {
        setAssessmentResults((currentResults) => {
          const initializedResults = Array.isArray(currentResults)
            ? currentResults
            : [];
          const processedData = ProcessAssessmentText([newData]);
          return [...initializedResults, ...processedData];
        });
      };

      const handleAssessmentComplete = () => {
        setIsAssessmentComplete(true);
      };

      setHaveMadeBackendRequest(true);
      getCurrentConversationId(authenticatedCurrentUser.uid)
        .then((conversationId) => {
          console.log("conversationId", conversationId);
          // Fetch Transcript first
          fetchTranscript(conversationId, handleDataReceived)
            .then(() => {
              console.log("Transcript stream complete.");
              return startAssessment(conversationId, handleAssessmentData);
            })
            .then(() => {
              console.log("Assessment stream complete.");
              handleAssessmentComplete();
            })
            .catch((error) => {
              console.error("Error during assessment: ", error);
              setAssessmentResults({
                error:
                  "We couldn't assess your conversation due to an error. Probably the conversation was too short. Try another call please.",
              });
            });
        })
        .catch((error) => {
          console.error("Failed to get conversation ID:", error);
        });
    }
  }, [authenticatedCurrentUser]);

  const calculateTotalScore = () => {
    if (!assessmentResults || assessmentResults.error) return 0;
    return assessmentResults.reduce((total, result) => {
      const rating = parseInt(result.rating, 10);
      return isNaN(rating) ? total : total + rating;
    }, 0);
  };

  const [anchorElTitles, setAnchorElTitles] = useState(
    Array(assessmentResults?.length || 0).fill(null),
  );
  const [anchorElRatings, setAnchorElRatings] = useState(
    Array(assessmentResults?.length || 0).fill(null),
  );

  return (
    <DashboardLayout>
      <MKBox
        component="header"
        position="absolute"
        minHeight="100vh"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <MKBox display="flex" flexDirection="column" alignItems="center" mb={5}>
          <SelectTabs
            tabValue={selectedTab}
            handleSetTabValue={handleSetTabValue}
            dataReady={transcriptDataReady}
          />
          {selectedTab === 0 ? (
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 8, sm: 7, md: 6 }}
              mx={3}
              width={{ xs: "90%", sm: "80%", md: "70%" }}
              maxWidth={{ sm: "500px", md: "1000px" }}
            >
              {assessmentResults ? (
                assessmentResults.error ? (
                  <ErrorDisplay errorMessage={assessmentResults.error} />
                ) : (
                  assessmentResults.map((result, index) => {
                    return (
                      <Grow
                        in={true}
                        style={{ transformOrigin: "0 0 0" }}
                        timeout={1000 + index * 500}
                        key={index}
                      >
                        <div>
                          <MKBox
                            variant="gradient"
                            bgColor="secondary"
                            coloredShadow="info"
                            borderRadius="lg"
                            p={2}
                            mx={2}
                            mt={-3}
                          >
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <MKTypography
                                  variant="h3"
                                  color="white"
                                  aria-owns={
                                    anchorElTitles[index] &&
                                    categoryDescriptions[result.title]
                                      ? `mouse-over-popover-${index}-title`
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={(event) => {
                                    if (categoryDescriptions[result.title]) {
                                      const newAnchorElTitles = [
                                        ...anchorElTitles,
                                      ];
                                      newAnchorElTitles[index] =
                                        event.currentTarget;
                                      setAnchorElTitles(newAnchorElTitles);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    const newAnchorElTitles = [
                                      ...anchorElTitles,
                                    ];
                                    newAnchorElTitles[index] = null;
                                    setAnchorElTitles(newAnchorElTitles);
                                  }}
                                >
                                  {result.title}
                                </MKTypography>
                                <Popover
                                  id={`mouse-over-popover-${index}-title`}
                                  sx={{
                                    pointerEvents: "none",
                                  }}
                                  open={Boolean(
                                    anchorElTitles[index] &&
                                      categoryDescriptions[result.title],
                                  )}
                                  anchorEl={anchorElTitles[index]}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  onClose={() => {
                                    const newAnchorElTitles = [
                                      ...anchorElTitles,
                                    ];
                                    newAnchorElTitles[index] = null;
                                    setAnchorElTitles(newAnchorElTitles);
                                  }}
                                  disableRestoreFocus
                                  slotProps={{
                                    paper: {
                                      sx: {
                                        backgroundColor:
                                          "rgba(255, 255, 255, 0.95)",
                                        boxShadow:
                                          "0px 5px 15px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "8px",
                                        marginTop: "10px",
                                      },
                                    },
                                  }}
                                >
                                  <MKBox p={2} maxWidth={500}>
                                    <MKTypography
                                      variant="body2"
                                      sx={{ whiteSpace: "pre-line" }}
                                    >
                                      {categoryDescriptions[result.title]}
                                    </MKTypography>
                                  </MKBox>
                                </Popover>
                              </Grid>
                              <Grid item>
                                <MKTypography
                                  variant="h3"
                                  color="white"
                                  aria-owns={
                                    anchorElRatings[index]
                                      ? `mouse-over-popover-${index}-rating`
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={(event) => {
                                    const newAnchorElRatings = [
                                      ...anchorElRatings,
                                    ];
                                    newAnchorElRatings[index] =
                                      event.currentTarget;
                                    setAnchorElRatings(newAnchorElRatings);
                                  }}
                                  onMouseLeave={() => {
                                    const newAnchorElRatings = [
                                      ...anchorElRatings,
                                    ];
                                    newAnchorElRatings[index] = null;
                                    setAnchorElRatings(newAnchorElRatings);
                                  }}
                                >
                                  {result.rating}
                                </MKTypography>
                              </Grid>
                            </Grid>
                          </MKBox>
                          <MKBox p={3} mb={3}>
                            <MKTypography variant="body2" color="text" mb={1}>
                              {result.description}
                            </MKTypography>
                          </MKBox>
                        </div>
                      </Grow>
                    );
                  })
                )
              ) : (
                <MKBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  p={3}
                >
                  <CircularProgress size={80} />
                  <MKTypography variant="h5" color="text" mt={2}>
                    {loadingMessages[messageIndex]}
                  </MKTypography>
                </MKBox>
              )}
            </MKBox>
          ) : (
            <ConversationBox
              messages={messages}
              callRecordingUrl={callRecordingUrl}
              extractedData={extractedData}
            />
          )}
          <NavigationButtons increaseRank={increaseRank} />
        </MKBox>
        <CongratsModal
          isAssessmentComplete={isAssessmentComplete}
          handleClose={() => setIsAssessmentComplete(false)}
          totalScore={calculateTotalScore()}
        />
      </MKBox>
    </DashboardLayout>
  );
};

export default Assessment;
