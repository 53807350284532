import httpService from "../services/HttpService";

export function fetchOrganisationConversations(organisationId) {
  return httpService
    .performPostRequest("fetch-organisation-conversations", {
      organisation_id: organisationId,
    })
    .then((response) => {
      // Assuming the response includes both conversations array and total_count
      return {
        totalConversations: response.total_count,
        rankedUsersAndConversations: response.ranked_users_and_conversations,
        bestCall: response.best_call,
      };
    });
}
