import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import MKModal from "components/MKModal";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import BuildIcon from "@mui/icons-material/Build";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const BotChoiceModal = ({ isOpen, onContinueWithSimulation }) => {
  const navigate = useNavigate();

  const handleCreateBot = () => {
    navigate("/build-your-simulation");
  };

  return (
    <MKModal
      open={isOpen}
      handleClose={onContinueWithSimulation}
      canClose={true}
      width={500}
    >
      <MKBox textAlign="center" mb={4}>
        <MKTypography variant="h4" component="h2" gutterBottom>
          Choose Your Path
        </MKTypography>
      </MKBox>

      <MKBox display="flex" gap={4}>
        <MKButton
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleCreateBot}
          sx={{ flex: 1, minHeight: 180, minWidth: 280 }}
        >
          <MKBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            p={1}
          >
            <BuildIcon sx={{ fontSize: 50 }} />
            <MKBox>
              <MKTypography variant="h6" color="dark" textTransform="none">
                Create Your Bot
              </MKTypography>
              <MKTypography
                variant="button"
                color="text"
                fontWeight="regular"
                opacity={0.8}
              >
                takes 2 minutes
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKButton>

        <MKButton
          variant="gradient"
          color="primary"
          size="large"
          onClick={onContinueWithSimulation}
          sx={{ flex: 1, minHeight: 180, minWidth: 280 }}
        >
          <MKBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            p={1}
            color="white"
          >
            <RocketLaunchIcon sx={{ fontSize: 50, color: "white" }} />
            <MKBox>
              <MKTypography variant="h6" color="white" textTransform="none">
                Use Set Simulations
              </MKTypography>
              <MKTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
              >
                Start right away
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKButton>
      </MKBox>
    </MKModal>
  );
};

BotChoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onContinueWithSimulation: PropTypes.func.isRequired,
};

export default BotChoiceModal;
