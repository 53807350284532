import React from "react";

import Icon from "@mui/material/Icon";

import Assessment from "./pages/Assessment/Assessment";
import NewView from "./pages/NewView/NewView";
import NotAvailable from "./pages/NotAvailable";
import MySimulations from "./pages/MySimulations";
import MyReport from "./pages/MyReport";
import MyTeam from "./pages/MyTeam";
import App from "./App";
import Welcome from "./pages/Welcome";
import ThankYouPage from "./pages/ThankYou";
import SignOut from "./components/SignOut";
import RedirectPage from "./pages/RedirectPage";
// import StandoutsShowcase from "./pages/StandoutsShowcase/StandoutsShowcase";
// import Automagically from "./pages/Automagically";
import BuildYourBot from "./pages/BuildYourBot";
// import SaaStock from "./pages/Saastock";
import SaaStockCompetition from "./pages/SaaStockCompetition";
import ContextWrapper from "./contextWrapper";

export const createRoutes = (additionalRoute) => {
  const routes = [
    {
      path: "/",
      element: (
        <ContextWrapper>
          <App />
        </ContextWrapper>
      ),
      children: [
        { index: true, element: <Welcome /> },
        { path: ":variant", element: <Welcome /> },
        { path: "callview", element: <NewView /> },
        { path: "saastock", element: <SaaStockCompetition /> },
        { path: "assessment", element: <Assessment /> },
        { path: "build-your-simulation", element: <BuildYourBot /> },
        { path: "my-simulations", element: <MySimulations /> },
        { path: "my-report", element: <MyReport /> },
        { path: "my-team", element: <MyTeam /> },
        { path: "champions-showcase", element: <NotAvailable /> },
        { path: "automagically", element: <NotAvailable /> },
        { path: "thankyou", element: <ThankYouPage /> },
        { path: "signout", element: <SignOut /> },
        { path: "redirect", element: <RedirectPage /> },
        { path: "not-available", element: <NotAvailable /> },
      ],
    },
  ];

  const navMetadata = [
    {
      type: "collapse",
      name: "Random Simulation",
      key: "random-simulation",
      path: "/callview",
      component: <NewView />,
      icon: <Icon fontSize="small">phone_in_talk</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "Assessment",
      key: "assessment",
      path: "/assessment",
      component: <Assessment />,
      icon: <Icon fontSize="small">assessment</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "divider",
      key: "divider-1",
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "Build Your Simulation",
      key: "build-your-simulation",
      path: "/build-your-simulation",
      component: <NotAvailable />,
      icon: <Icon fontSize="small">build_circle</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "My Simulations",
      key: "my-simulations",
      path: "/my-simulations",
      component: <MySimulations />,
      icon: <Icon fontSize="small">videogame_asset</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "My Report",
      key: "my-report",
      path: "/my-report",
      component: <MyReport />,
      icon: <Icon fontSize="small">bar_chart</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "My Team",
      key: "my-team",
      path: "/my-team",
      component: <MyTeam />,
      icon: <Icon fontSize="small">group</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "divider",
      key: "divider-2",
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "Champions Showcase",
      key: "champions-showcase",
      path: "/champions-showcase",
      component: <NotAvailable />,
      icon: <Icon fontSize="small">school</Icon>,
      sideNav: true,
      pageNav: false,
    },
    {
      type: "collapse",
      name: "Automagically",
      key: "automagically",
      path: "/automagically",
      component: <NotAvailable />,
      icon: <Icon fontSize="small">sparkles</Icon>, // Using 'sparkles' as another alternative icon representing 'magic'
      sideNav: true,
      pageNav: false,
    },
    // Additional route conditionally added
    ...(additionalRoute
      ? [
          {
            type: "collapse",
            name: additionalRoute.name,
            key: "external-link",
            href: additionalRoute.href,
            icon: <Icon fontSize="small">{additionalRoute.icon}</Icon>,
            sideNav: true,
            pageNav: false,
          },
        ]
      : []),
  ];

  return { routes, navMetadata };
};

export default createRoutes;
