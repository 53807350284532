import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRank } from "../features/user/userSlice";
import { useAuth } from "../services/firebase/AuthContext";

export const useAfterCallViewNavigation = (setIsExerciseEndModalOpen) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rank } = useSelector((state) => state.user);
  const { currentUser } = useAuth();

  const goToAssessment = () => {
    setIsExerciseEndModalOpen(false);

    console.log("Current User in AfterCallViewNavigation:", currentUser);
    if (currentUser) {
      if (rank === 1) {
        navigate("/assessment");
      } else {
        navigate("/assessment");
      }
    } else {
      console.log(`Current user's rank: ${rank}`);
      if (rank >= 1) {
        console.log(
          "Navigating to the assessment page for users with rank >= 1",
        );
        dispatch(setRank(rank + 1));
        navigate("/assessment");
      }
    }
  };

  return goToAssessment;
};
