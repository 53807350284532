import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import colors from "assets/theme/base/colors";

const AudioVisualizer = ({ volumeRef, size }) => {
  const canvasRef = useRef(null);
  const [smoothedVolume, setSmoothedVolume] = useState(0);
  const smoothingFactor = 0.3; // Adjust this value to control the smoothing speed

  const draw = (volume) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Define the center of the canvas
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    // Base radius of the circle
    const baseRadius = Math.min(canvas.width, canvas.height) / 4;
    const maxRadiusIncrease = 50; // Maximum additional radius based on volume
    const scalingFactor = 0.15;

    // Calculate radius increase based on smoothed volume
    const radiusIncrease = Math.min(
      volume * scalingFactor * baseRadius,
      maxRadiusIncrease,
    );
    const radius = baseRadius + radiusIncrease;

    // Draw a simple circle that grows and shrinks with volume
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.fillStyle = colors.secondary.main;
    ctx.fill();
  };

  useEffect(() => {
    let animationFrameId;
    let localSmoothedVolume = smoothedVolume;

    const renderFrame = () => {
      const currentVolume = volumeRef.current;
      localSmoothedVolume +=
        smoothingFactor * (currentVolume - localSmoothedVolume);

      draw(localSmoothedVolume);
      setSmoothedVolume(localSmoothedVolume); // Update state for React, but don't rely on it for animation

      animationFrameId = requestAnimationFrame(renderFrame);
    };

    renderFrame();

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    };
  }, []); // Remove smoothedVolume from dependencies

  return <canvas ref={canvasRef} width={size} height={size} />;
};

AudioVisualizer.propTypes = {
  volumeRef: PropTypes.shape({
    current: PropTypes.number,
  }),
  size: PropTypes.string.isRequired,
};

export default AudioVisualizer;
