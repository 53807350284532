import React from "react";
import { useNavigate } from "react-router-dom";
import InformationModal, {
  openInformationModal,
} from "components/BaseModal/InformationModal";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useMediaQuery, useTheme } from "@mui/material";
import { Fade } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedRoleplay } from "../../features/user/userSlice";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CenteredFooter from "components/Footer";
import PageLayout from "layouts/PageLayout";
import BackgroundVideo from "components/BackgroundVideo/BackgroundVideo";

import videoPlaceholder from "assets/images/background/video_background.png";

function SaaStockCompetition() {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const videoBackground =
    "https://storage.googleapis.com/skylar_public_assets/background_videos/855441-hd_1920_1080_25fps.mp4";

  const handleStartCompetition = () => {
    dispatch(setSelectedRoleplay("cold_call_saastock_competition")); //TODO this doesnt work on a new login need to fix
    navigate("/callview");
  };

  const handleViewRules = () => {
    openInformationModal({
      title: "Competition Rules",
      content: `
        1. To be eligible, participants must submit a call by the end of day on November 22nd, 2024.
        2. To enter the draw, participants need to score above 12 in the assessment after the call.
        3. Each call that scores above 12 counts as a separate entry. You can submit multiple entries.
        4. One person will be randomly selected to win £500.
      `,
    });
  };

  return (
    <PageLayout>
      <MKBox
        component="header"
        position="absolute"
        minHeight="100vh"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <BackgroundVideo
          videoSrc={videoBackground}
          placeholderSrc={videoPlaceholder}
          showFilter={true}
        />
        <MKBox
          display="flex"
          alignItems="center"
          minHeight="70vh"
          mt={5}
          style={{ zIndex: 1 }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mx="auto"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  mt: 10,
                  mb: 1,
                },
              })}
            >
              <MKTypography
                variant="body2"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["xs"],
                  },
                })}
              >
                Welcome to the National Sales Conference Cold Calling
                Competition!
              </MKTypography>
              <Fade in={true} timeout={700}>
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  Test Your Cold Calling Skills
                </MKTypography>
              </Fade>
              <Fade in={true} timeout={700}>
                <MKTypography
                  variant="body1"
                  color="white"
                  mt={{ xs: 1, lg: 3 }}
                  mb={6}
                  px={{ xs: 3, lg: 6 }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["md"],
                    },
                  })}
                >
                  Cold call an AI-powered client. Compete with fellow attendees
                  to book a meeting. And a chance to win £500!
                </MKTypography>
              </Fade>
              <Fade in={true} timeout={1000}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={5}
                  mt={{ xs: 3, lg: 5 }}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {isSmallScreen ? (
                    <>
                      <MKButton onClick={handleStartCompetition} color="white">
                        Start Cold Call
                      </MKButton>
                      <MKButton
                        onClick={handleViewRules}
                        variant="text"
                        color="white"
                      >
                        View Rules
                      </MKButton>
                    </>
                  ) : (
                    <>
                      <MKButton
                        onClick={handleViewRules}
                        variant="text"
                        color="white"
                      >
                        View Rules
                      </MKButton>
                      <MKButton onClick={handleStartCompetition} color="white">
                        Start Cold Call
                      </MKButton>
                    </>
                  )}
                </Stack>
              </Fade>
            </Grid>
          </Container>
        </MKBox>
        <MKBox mt="30vh" minHeight="10vh">
          <CenteredFooter light={true} />
        </MKBox>
      </MKBox>
      <InformationModal />
    </PageLayout>
  );
}

export default SaaStockCompetition;
