import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import Slide from "@mui/material/Slide";

import MKModal from "components/MKModal";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { AuthContext } from "services/firebase/AuthContext";

const EnterNameModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { updateUserName } = useContext(AuthContext);

  const validateName = (name) => {
    const trimmedName = name.trim();
    const isValid = /^[a-zA-Z-]+$/.test(trimmedName);
    if (!isValid) {
      setAlertMessage("Please enter a valid first name only.");
      setShowAlert(true);
      return false;
    }
    setShowAlert(false);
    return true;
  };

  const closeOnSuccess = async () => {
    if (!validateName(name)) return;

    try {
      await updateUserName(name);
      setOpen(false);
    } catch (error) {
      console.error("Failed to update user name:", error);
    }
  };

  const handleNameSubmit = async (event) => {
    event.preventDefault();
    await closeOnSuccess();
  };

  const handleClose = async () => {
    await closeOnSuccess();
  };

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  return (
    <MKModal
      open={open}
      handleClose={handleClose}
      canClose={name.trim() !== ""}
    >
      <Slide direction="down" in={showAlert} timeout={200} unmountOnExit>
        <MKBox position="absolute" top="0.5rem" left={0} width="100%">
          <MKAlert
            width="25%"
            mx="auto"
            color="error"
            sx={{
              minHeight: "2.5rem !important",
              py: 1,
              justifyContent: "center",
            }}
          >
            <MKTypography variant="body2" color="white" fontWeight="regular">
              {alertMessage}
            </MKTypography>
          </MKAlert>
        </MKBox>
      </Slide>
      <MKBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        p={2}
        mb={3}
        textAlign="center"
      >
        <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Enter Your Name to continue
        </MKTypography>
      </MKBox>
      <MKBox textAlign="center" mx={2} mb={2}>
        <MKTypography
          variant="subtitle2"
          fontWeight="regular"
          color="text"
          mt={1}
        >
          Get access to multiple roleplays, detailed assessments, recordings and
          more!
        </MKTypography>
      </MKBox>
      <MKBox component="form" role="form">
        <MKBox mb={2}>
          <MKInput
            type="string"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </MKBox>
      </MKBox>
      <MKBox mt={4} mb={1}>
        <MKButton
          variant="gradient"
          color="primary"
          fullWidth
          onClick={handleNameSubmit}
        >
          Enter
        </MKButton>
      </MKBox>
    </MKModal>
  );
});

EnterNameModal.displayName = "EnterNameModal";

export default EnterNameModal;
