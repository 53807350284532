import React from "react";
import MKBox from "components/MKBox";

function LoadingSkeleton() {
  return (
    <MKBox
      sx={{
        width: "100%",
        height: "56px",
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
        animation: "pulse 1.5s ease-in-out infinite",
        "@keyframes pulse": {
          "0%": {
            opacity: 1,
          },
          "50%": {
            opacity: 0.4,
          },
          "100%": {
            opacity: 1,
          },
        },
      }}
    />
  );
}

export default LoadingSkeleton;
