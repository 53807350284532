import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";

import MKButton from "components/MKButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const BaseModal = ({ isOpen, title, children, onClose, actions }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      TransitionProps={{
        style: {
          transitionTimingFunction: theme.transitions.easing.easeInOut,
        },
      }}
    >
      <DialogTitle sx={{ typography: "h4" }}>{title}</DialogTitle>
      <DialogContent sx={{ typography: "body2", marginLeft: 0.5 }}>
        {children}
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: actions.length === 1 ? "flex-end" : "space-between",
        }}
      >
        {actions.map((action, index) => (
          <MKButton
            key={index}
            onClick={action.onClick}
            color={action.color || "info"}
            variant={action.highlight ? "contained" : "text"}
            style={{
              fontWeight: action.highlight ? "bold" : "normal",
              backgroundColor: action.highlight
                ? theme.palette.secondary.main
                : "",
              color: action.highlight
                ? theme.palette.secondary.contrastText
                : "",
            }}
          >
            {action.label}
          </MKButton>
        ))}
      </DialogActions>
    </Dialog>
  );
};

BaseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      color: PropTypes.string,
      highlight: PropTypes.bool,
    }),
  ).isRequired,
};

export default BaseModal;
