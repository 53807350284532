import React, { useState, useEffect } from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "layouts/DashboardLayout";

const NotAvailable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    // Simulate loading and access check
    setTimeout(() => {
      setIsLoading(false);
      // Simulate access check (set to false to show the access message)
      setHasAccess(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return (
      <DashboardLayout>
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </MKBox>
      </DashboardLayout>
    );
  }

  if (!hasAccess) {
    return (
      <DashboardLayout>
        <MKBox textAlign="center" pt={12} pb={3}>
          <MKTypography variant="h4" mb={3}>
            It looks like you don&apos;t have access to this page.
          </MKTypography>
          <MKTypography variant="h6" mb={3}>
            Book a demo and unlock full access.
          </MKTypography>
          <MKButton
            component="a"
            href="https://calendly.com/ivelin-pxm/15min"
            target="_blank"
            rel="noreferrer"
            color="primary"
          >
            Book a demo
          </MKButton>
        </MKBox>
      </DashboardLayout>
    );
  }

  // If the user has access, render the course content here
  return <DashboardLayout>{/* Course content goes here */}</DashboardLayout>;
};

export default NotAvailable;
