import React, { useState } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import Icon from "@mui/material/Icon";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ExpandableSection({ title, children, isLoading }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <MKBox p={1}>
      <MKBox
        display="flex"
        alignItems="center"
        p={2}
        onClick={handleToggle}
        borderRadius="md"
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          position: "relative",
          zIndex: 1000,
        }}
      >
        <MKTypography variant="h5" color="text">
          {title}
        </MKTypography>
        <MKBox ml={1}>
          <Icon>{isExpanded ? "expand_less" : "expand_more"}</Icon>
        </MKBox>
      </MKBox>
      <motion.div
        initial="hidden"
        animate={isExpanded ? "visible" : "hidden"}
        variants={{
          visible: { opacity: 1, height: "auto" },
          hidden: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.3 }}
      >
        <MKBox p={2}>
          {isLoading ? (
            <MKTypography variant="body2" color="text" textAlign="center">
              Loading {title.toLowerCase()}...
            </MKTypography>
          ) : (
            children
          )}
        </MKBox>
      </motion.div>
    </MKBox>
  );
}

ExpandableSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default ExpandableSection;
