import React, { useState, useRef } from "react";
import TextSubmitModal from "components/BaseModal/TextSubmitModal";
import SignUpModal from "components/SignUpModal";
import {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "services/firebase/firebase";

const LoginModal = () => {
  const [textModalOpen, setTextModalOpen] = useState(true);
  const signUpModalRef = useRef(null);

  const handleTextModalClose = (inputValues) => {
    const { email } = inputValues;
    console.log("email to create user with", email);
    if (email) {
      createUserWithEmailAndPassword(auth, email, "SkylarIsTheFuture2024")
        .then((userCredential) => {
          console.log(
            "User signed up with email and password",
            userCredential.user,
          );
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            console.log("Email already in use, attempting to sign in.");
            signInWithEmailAndPassword(auth, email, "SkylarIsTheFuture2024")
              .then((userCredential) => {
                console.log(
                  "User signed in with email and password",
                  userCredential.user,
                );
              })
              .catch((signInError) => {
                console.error(
                  "Error signing in with email and password",
                  signInError,
                );
                signUpModalRef.current.open();
              });
          } else {
            console.error("Error signing up with email and password", error);
            signUpModalRef.current.open();
          }
        });
    }
    setTextModalOpen(false);
  };

  return (
    <>
      <TextSubmitModal
        isOpen={textModalOpen}
        title="Enter your email to start"
        requireInput={true}
        onClose={(inputValues) => handleTextModalClose(inputValues)}
        actions={[
          {
            label: "Let's go",
            onClick: () => setTextModalOpen(false),
            highlight: true,
          },
        ]}
        inputs={[
          {
            name: "email",
            placeholder: "e.g. taylor@example.com",
            requireInput: true,
            type: "email",
          },
        ]}
      />
      <SignUpModal ref={signUpModalRef} />
    </>
  );
};

export default LoginModal;
