import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Add this import

import DashboardLayout from "layouts/DashboardLayout";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// import ReportingSwiper from "pages/MyReport/reportingSwiper";
import NotAuthorized from "pages/MyReport/NotAuthorized";

const MyReport = () => {
  const authLevel = useSelector((state) => state.user.authLevel);
  // Assuming we have a way to get the number of completed roleplays
  const completedRoleplays =
    useSelector((state) => state.user.totalRoleplaysToday) || 0;

  const navigate = useNavigate();

  // Animation variants with slower transitions
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Increased from 0.3
        duration: 0.8, // Added overall duration
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8, // Increased from default
        ease: "easeOut",
      },
    },
  };

  if (authLevel !== null && authLevel !== "general") {
    return (
      <DashboardLayout>
        <MKBox
          component={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="70vh"
          textAlign="center"
          p={3}
        >
          <MKTypography
            component={motion.h3}
            variants={itemVariants}
            variant="h3"
            color="primary"
            mb={3}
          >
            Your Report is Coming Soon!
          </MKTypography>
          <MKBox
            component={motion.div}
            variants={itemVariants}
            bgColor="secondary.main"
            borderRadius="xl"
            shadow="lg"
            p={4}
            maxWidth="600px"
          >
            <MKTypography variant="body1" mb={3}>
              We&apos;re currently collecting data to generate your personalized
              report. Keep up the great work with your roleplays!
            </MKTypography>
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={3}
            >
              <MKTypography
                component={motion.h2}
                variant="h2"
                color="primary"
                mr={2}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }} // Increased duration
              >
                {completedRoleplays}
              </MKTypography>
              <MKTypography variant="h4" color="text">
                / 10
              </MKTypography>
            </MKBox>
            <MKTypography variant="body2" color="text" mb={4}>
              Roleplays completed
            </MKTypography>
            <MKButton
              component={motion.button}
              whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate("/my-simulations")}
            >
              Continue Roleplaying
            </MKButton>
          </MKBox>
        </MKBox>
      </DashboardLayout>
    );
  }

  return <NotAuthorized />;
};

export default MyReport;
