import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import DashboardLayout from "layouts/DashboardLayout";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import DataTable from "components/DataTable";
import MKTypography from "components/MKTypography";
import SpiderChart from "components/SpiderChart";
import authorsTableData from "./authorsTableData";

const NotAuthorized = () => {
  const { columns, rows } = authorsTableData();

  const handleBookDemo = () => {
    window.open("https://calendly.com/ivelin-pxm/15min");
  };

  return (
    <DashboardLayout>
      <MKBox ml={3}>
        <MKTypography variant="h4">My Report</MKTypography>

        <MKBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item md={6} xs={12}>
              <Card>
                <Backdrop
                  open={true}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    color: "#000",
                    backdropFilter: "blur(2px)",
                    borderRadius: "20px",
                  }}
                >
                  <MKButton
                    variant="contained"
                    onClick={handleBookDemo}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Book a Demo to view insights
                  </MKButton>
                </Backdrop>

                <MKBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MKTypography variant="h6" color="white">
                    Key Improvement Target - Discovery
                  </MKTypography>
                </MKBox>

                <MKBox p={3} mb={3}>
                  <MKTypography variant="body2" color="text" mb={1}>
                    <p>
                      Excellent work in consistently maintaining a positive tone
                      throughout your practice calls, particularly when you
                      tackled the complex features of our latest software
                      update.
                    </p>
                    <p>
                      A specific opportunity for enhancement lies in your
                      questioning technique. For instance, instead of asking Do
                      you find our current platform user-friendly?`&apos;, pivot
                      to How does our current platform&apos;s user experience
                      align with your daily workflow needs?`&apos; This not only
                      invites detailed feedback but also demonstrates a deeper
                      level of understanding and commitment to tailoring
                      solutions.
                    </p>
                    <p>
                      Additionally, integrating more strategic pauses after key
                      points could enhance client absorption of information,
                      similar to the pause you effectively used after explaining
                      the cost-saving benefits in your most recent call. Keep
                      leveraging these insights to refine your approach, and
                      you&apos;ll significantly elevate the impact of your sales
                      conversations.
                    </p>
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
            <Grid item md={6} xs={12}>
              <Card>
                <Backdrop
                  open={true}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    color: "#000",
                    backdropFilter: "blur(2px)",
                    borderRadius: "20px",
                  }}
                >
                  <MKButton
                    variant="contained"
                    onClick={handleBookDemo}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Book a Demo to view insights
                  </MKButton>
                </Backdrop>
                <MKBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MKTypography variant="h6" color="white">
                    Your Performance Report
                  </MKTypography>
                </MKBox>
                <MKBox p={3}>
                  <SpiderChart />
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Backdrop
                  open={true}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    color: "#000",
                    backdropFilter: "blur(3px)",
                    borderRadius: "20px",
                  }}
                >
                  <MKButton
                    variant="contained"
                    onClick={handleBookDemo}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Book a Demo to view insights
                  </MKButton>
                </Backdrop>
                <MKBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MKTypography variant="h6" color="white">
                    Report Table
                  </MKTypography>
                </MKBox>
                <MKBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </DashboardLayout>
  );
};

NotAuthorized.propTypes = {};

export default NotAuthorized;
