import React from "react";
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import MKBadge from "components/MKBadge";

// Images
import defaultAvatar from "assets/images/avatars/question_mark_avatar_1.png";

export default function data(authorsData) {
  // Sort authorsData by average_score in descending order
  const sortedAuthorsData = [...authorsData].sort(
    (a, b) => b.average_score - a.average_score,
  );

  const Author = ({ image, name, email }) => (
    <MKBox display="flex" alignItems="center" lineHeight={1}>
      <MKAvatar src={image || defaultAvatar} name={name} size="sm" />
      <MKBox ml={2} lineHeight={1}>
        <MKTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MKTypography>
        <MKTypography variant="caption">{email}</MKTypography>
      </MKBox>
    </MKBox>
  );

  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };

  const StatusBadge = ({ criteria }) => (
    <MKBox ml={-1}>
      <MKBadge
        badgeContent={criteria || "None"}
        color={criteria ? "success" : "dark"}
        variant="gradient"
        size="sm"
      />
    </MKBox>
  );

  StatusBadge.propTypes = {
    criteria: PropTypes.string,
  };

  const rows = sortedAuthorsData.map((author) => ({
    author: (
      <Author image={defaultAvatar} name={author.name} email={author.email} />
    ),
    numberOfCalls: (
      <MKTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {author.count}
      </MKTypography>
    ),
    averageScore: (
      <MKTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {author.average_score.toFixed(2)}
      </MKTypography>
    ),
    bestArea: <StatusBadge criteria={author.strongest_criteria} />,
  }));

  return {
    columns: [
      { Header: "Name", accessor: "author", align: "left" },
      {
        Header: "Average Score",
        accessor: "averageScore",
        align: "center",
        sortable: true,
        canSort: true,
      },
      { Header: "Number of Calls", accessor: "numberOfCalls", align: "center" },
      { Header: "Best Area", accessor: "bestArea", align: "center" },
    ],
    rows,
  };
}
