// utils/browserCheck.js
export default function isSafari() {
  const userAgent = navigator.userAgent;
  // Check for mobile indicators
  const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
  // Check for Safari desktop
  const isSafariDesktop =
    /Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !isMobile;
  return isSafariDesktop;
}
