// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

// First, let's extract the initial custom persona state into a constant
const initialCustomPersona = {
  website: null,
  name: null,
  avatarUrl: null,
  title: null,
  age: null,
  location: null,
  callType: null,
  clientCompanyInfo: {
    name: null,
    employeeCount: null,
    description: null,
  },
  challenges: [],
  sellerCompanyName: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    authLevel: null,
    selectedRoleplay: null,
    rank: 1, // default rank
    displayName: null,
    email: null,
    totalRoleplaysToday: 0,
    orgId: null,
    freeRoleplaysRemaining: 0,
    hasCreatedABot: false,
    customPersona: initialCustomPersona,
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAuthLevel: (state, action) => {
      state.authLevel = action.payload;
    },
    setSelectedRoleplay: (state, action) => {
      state.selectedRoleplay = action.payload;
    },
    setRank: (state, action) => {
      state.rank = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setTotalRoleplaysToday: (state, action) => {
      console.log("Setting total roleplays today to:", action.payload);
      state.totalRoleplaysToday = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
    setFreeRoleplaysRemaining: (state, action) => {
      state.freeRoleplaysRemaining = action.payload;
    },
    setCustomPersona: (state, action) => {
      state.customPersona = {
        ...state.customPersona,
        ...action.payload,
      };
      state.hasCreatedABot = true;
    },
    updatePersonaField: (state, action) => {
      const { field, value } = action.payload;
      if (field.includes(".")) {
        const [parent, child] = field.split(".");
        state.customPersona[parent][child] = value;
      } else {
        state.customPersona[field] = value;
      }
    },
    clearCustomPersona: (state) => {
      state.customPersona = initialCustomPersona;
      state.hasCreatedABot = false;
    },
  },
});

export const {
  setAuthenticated,
  setAuthLevel,
  setSelectedRoleplay,
  setRank,
  setDisplayName,
  setEmail,
  setTotalRoleplaysToday,
  setOrgId,
  setFreeRoleplaysRemaining,
  setCustomPersona,
  updatePersonaField,
  clearCustomPersona,
} = userSlice.actions;

export default userSlice.reducer;
