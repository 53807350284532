/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import React from "react";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MKBox from "components/MKBox"; // Updated import
import MKTypography from "components/MKTypography"; // Updated import

function MultipleStatisticsCard({ data }) {
  return (
    <Card>
      <MKBox display="flex" justifyContent="space-between" pt={1} px={2}>
        {data.map((item, index) => (
          <MKBox
            key={index}
            flex={1}
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <MKTypography
              variant="button"
              fontWeight="light"
              color="text"
              textAlign="center"
            >
              {item.title}
            </MKTypography>
            <MKTypography variant="h4" textAlign="center">
              {item.count}
            </MKTypography>
            <Divider sx={{ width: "100%", my: 1 }} />

            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              pb={1}
              px={2}
            >
              <MKTypography
                component="p"
                variant="button"
                color="text"
                display="flex"
              >
                <MKTypography
                  component="span"
                  variant="button"
                  fontWeight="bold"
                  color={item.percentage.color}
                >
                  {item.percentage.amount}
                </MKTypography>
                &nbsp;{item.percentage.label}
              </MKTypography>
            </MKBox>
          </MKBox>
        ))}
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of MultipleStatisticsCard
MultipleStatisticsCard.defaultProps = {
  data: [],
};

MultipleStatisticsCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      percentage: PropTypes.shape({
        color: PropTypes.oneOf([
          "primary",
          "secondary",
          "info",
          "success",
          "warning",
          "error",
          "dark",
          "white",
        ]),
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }).isRequired,
    }),
  ).isRequired,
};

export default MultipleStatisticsCard;
