import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SocketService from "../../services/SocketService";

export const connectSocket = createAsyncThunk(
  "socket/connect",
  async (_, { dispatch, rejectWithValue }) => {
    const socketService = SocketService.getInstance();
    try {
      const conversationId = await socketService.connect();
      // Assuming you have an action to set the conversation ID in the store
      dispatch(setConversationId(conversationId));
      return conversationId;
    } catch (error) {
      console.error("Error connecting to socket:", error.message);
      return rejectWithValue(error.message);
    }
  },
);

export const disconnectSocket = createAsyncThunk(
  "socket/disconnect",
  async (_, { dispatch, rejectWithValue }) => {
    const socketService = SocketService.getInstance();
    try {
      await socketService.disconnect();
      dispatch(resetSocketState()); // Reset the socket state after disconnecting
    } catch (error) {
      console.error("Error disconnecting from socket:", error.message);
      return rejectWithValue(error.message);
    }
  },
);

// Example reducer for handling the conversation ID, adjust according to your store structure
const socketSlice = createSlice({
  name: "socket",
  initialState: {
    conversationId: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setConversationId(state, action) {
      state.conversationId = action.payload;
    },
    resetSocketState(state) {
      state.conversationId = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectSocket.pending, (state) => {
        state.status = "loading";
      })
      .addCase(connectSocket.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.conversationId = action.payload;
      })
      .addCase(connectSocket.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(disconnectSocket.fulfilled, () => {
        console.debug("Socket disconnected successfully.");
      })
      .addCase(disconnectSocket.rejected, (state, action) => {
        state.error = action.payload;
        console.error("Failed to disconnect socket:", state.error);
      });
  },
});

export const { setConversationId, resetSocketState } = socketSlice.actions;

export default socketSlice.reducer;
