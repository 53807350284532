import React, { useMemo } from "react";
import { Radar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import colors from "assets/theme/base/colors";

const SpiderChart = () => {
  // Sample data for the SpiderChart
  const data = useMemo(
    () => ({
      labels: ["Pitch", "Opener", "Qualification", "Next Steps", "Discovery"],
      datasets: [
        {
          label: "Your Cold Calling Report",
          data: [65, 59, 90, 81, 44],
          fill: true,
          backgroundColor: "rgba(255, 160, 163, 0.2)", // Updated to tertiary color with opacity
          borderColor: colors.tertiary.main, // Use tertiary color
          pointBackgroundColor: colors.tertiary.main, // Use tertiary color
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: colors.tertiary.main, // Use tertiary color
        },
      ],
    }),
    [],
  ); // Dependencies array is empty, so data is computed once

  const options = useMemo(
    () => ({
      scales: {
        r: {
          // 'r' is used for radial scales in Chart.js version 3.x
          angleLines: {
            display: false,
          },
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
      plugins: {
        legend: {
          position: "top",
        },
      },
      maintainAspectRatio: false,
    }),
    [],
  ); // Dependencies array is empty, so options are computed once

  return (
    <div style={{ height: "400px" }}>
      {" "}
      {/* Adjust the height as needed */}
      <Radar data={data} options={options} />
    </div>
  );
};

export default SpiderChart;
