import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Grid from "@mui/material/Grid";
import { analyzeWebsite } from "features/websiteAnalysis/websiteAnalysisSlice";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";
import MKFileUpload from "components/MKFileUpload";

const MotionGrid = motion(Grid);

function normalizeUrl(input) {
  // Remove leading/trailing whitespace
  let url = input.trim();

  // Remove any existing protocol
  url = url.replace(/^(https?:\/\/)?(www\.)?/, "");

  // Remove any paths or query parameters
  url = url.split("/")[0];

  // Add https:// protocol
  return `https://${url}`;
}

function isValidUrl(string) {
  // Basic domain validation regex
  const domainRegex =
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

  try {
    const normalized = normalizeUrl(string);
    const url = new URL(normalized);
    return domainRegex.test(url.hostname);
  } catch (_) {
    return false;
  }
}

function StepOne({ onSubmit }) {
  const dispatch = useDispatch();
  const [website, setWebsite] = useState("");
  const [urlError, setUrlError] = useState("");
  const [files, setFiles] = useState([]);
  const [showMaterials, setShowMaterials] = useState(false);

  const { status, error } = useSelector((state) => state.websiteAnalysis);
  const { hasCreatedABot } = useSelector((state) => state.user);

  // Watch for successful completion
  useEffect(() => {
    if (status === "initiated") {
      onSubmit({ website, files }, 1);
    }
  }, [status, website, files, onSubmit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUrlError("");

    if (hasCreatedABot) {
      setUrlError(
        "Free users can only create one bot. Please upgrade your plan to create more bots.",
      );
      return;
    }

    if (!website) {
      setUrlError("Please enter a website URL");
      return;
    }

    if (!isValidUrl(website)) {
      setUrlError("Please enter a valid website domain (e.g., example.com)");
      return;
    }

    const normalizedUrl = normalizeUrl(website);
    dispatch(analyzeWebsite(normalizedUrl));
  };

  const handleFileChange = (uploadedFiles) => {
    setFiles(uploadedFiles);
  };

  return (
    <MotionGrid
      container
      item
      xs={12}
      lg={7}
      sx={{ mx: "auto" }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <MKBox
        width="100%"
        component="form"
        method="post"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <MKBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MKInput
                variant="standard"
                label="Your Company's Website Domain (e.g., example.com)"
                placeholder="example.com"
                fullWidth
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                error={!!urlError}
                helperText={urlError}
                required
              />
            </Grid>
            <Grid container item justifyContent="center" xs={12}>
              <MKButton
                variant="text"
                color="info"
                onClick={() => setShowMaterials(!showMaterials)}
                startIcon={showMaterials ? "−" : "+"}
              >
                {showMaterials
                  ? "Hide Additional Materials"
                  : "Add Your Own Materials"}
              </MKButton>
            </Grid>
            {showMaterials && (
              <Grid item xs={12}>
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <MKFileUpload
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx,.txt,.mp3,.wav,.mp4,.avi,.mov,.pptx"
                    multiple
                    disabled
                  />
                </motion.div>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <MKTypography variant="caption" color="error">
                  {error}
                </MKTypography>
              </Grid>
            )}
            <Grid container item justifyContent="center" xs={12} mt={5}>
              <MKButton
                type="submit"
                variant="gradient"
                color="dark"
                sx={{ width: "200px" }}
                component={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={status === "initiated"}
              >
                {status === "initiated" ? "Analyzing..." : "Analyze Website"}
              </MKButton>
            </Grid>
          </Grid>
        </MKBox>
      </MKBox>
    </MotionGrid>
  );
}

StepOne.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StepOne;
