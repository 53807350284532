import React from "react";
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ClickableItem({ content, timestamp, onClick }) {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      p={1}
      onClick={() => onClick(timestamp)}
      borderRadius="md"
      sx={{
        cursor: "pointer",
        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
      }}
    >
      <MKTypography variant="body2" color="text">
        {timestamp} - {content}
      </MKTypography>
    </MKBox>
  );
}

ClickableItem.propTypes = {
  content: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ClickableItem;
