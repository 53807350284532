// AuthContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { auth, updateProfile } from "./firebase";
import { doc, setDoc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";

import { ORG_DOMAIN_MAPPING, SPECIFIC_EMAIL_MAPPING } from "config/orgConfig";

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  // const [initialCheckDone, setInitialCheckDone] = useState(false); //used for moving to pages for signed users
  const [currentUser, setCurrentUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [loadingAccountDetails, setLoadingAccountDetails] = useState(false);
  const [orgId, setOrgId] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async (user) => {
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
      const userEmail = user.email;
      let organisationId = null; // Default to no organization

      // Check if the user's email domain matches any in the ORG_DOMAIN_MAPPING
      const emailDomain = userEmail.split("@")[1];
      if (ORG_DOMAIN_MAPPING[emailDomain]) {
        organisationId = ORG_DOMAIN_MAPPING[emailDomain];
      }

      // Check if the specific email is in the SPECIFIC_EMAIL_MAPPING
      if (SPECIFIC_EMAIL_MAPPING[userEmail]) {
        organisationId = SPECIFIC_EMAIL_MAPPING[userEmail];
      }
      setOrgId(organisationId);
      // let navigateToHomePage = false;
      if (!userSnap.exists()) {
        console.log("Creating a new user document for:", user.uid);
        await setDoc(userRef, {
          dbEmail: userEmail,
          dbDisplayName: user.displayName,
          organisation_id: organisationId,
          totalRoleplays: 0,
          totalRoleplaysToday: 0,
          freeRoleplaysRemaining: organisationId === null ? 3 : 0,
          customPersona: null,
          hasCreatedABot: false,
          currentConversationId: null,
          allStartedConversationIds: [],
          allFinishedConversationIds: [],
          rank: 0,
          joinedDate: new Date(),
          lastActive: new Date(),
        });
      } else {
        // Update existing user document with any missing fields
        const userData = userSnap.data();
        const updates = {};
        console.log("userData", userData);
        // Check for missing fields and set default values
        if (
          !("freeRoleplaysRemaining" in userData) ||
          !("customPersona" in userData)
        ) {
          updates.freeRoleplaysRemaining = organisationId === null ? 3 : 0;
          updates.customPersona = null;
          updates.hasCreatedABot = false;
          console.log("updates", updates);
        }

        // Only update if there are missing fields
        if (Object.keys(updates).length > 0) {
          console.log("Updating existing user with missing fields:", updates);
          await updateDoc(userRef, updates);
        }
      }
    };

    return auth.onAuthStateChanged((user) => {
      setLoadingAccountDetails(true);
      if (!user) {
        console.debug("No user is currently signed in.");
        setCurrentUser(null);
        setAuthToken(null);
        setLoadingAccountDetails(false);
        setOrgId(null);
        return;
      }
      if (user) {
        console.debug("User is currently signed in");
        user
          .getIdToken()
          .then((token) => {
            setAuthToken(token);
          })
          .then((token) => {
            return fetchUserDetails(user).then(() => ({ user, token }));
          })
          .then(({ user }) => {
            setCurrentUser(user);
          })
          .catch((error) =>
            console.error("Failed to fetch user details:", error, user),
          )
          .finally(() => {
            setLoadingAccountDetails(false);
          });
      }
    }); // eslint-disable-next-line
  }, [navigate]);

  const signOut = async () => {
    try {
      await auth.signOut();
      // setInitialCheckDone(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const updateUserInfo = async (userId, newEmail, newDisplayName) => {
    const userRef = doc(db, "users", userId);

    const attemptUpdate = async (retryCount = 0) => {
      try {
        await updateDoc(userRef, {
          dbEmail: newEmail,
          dbDisplayName: newDisplayName,
          lastActive: new Date(),
        });
        console.debug("User info updated successfully");
      } catch (error) {
        if (retryCount < 3) {
          // Retry up to 3 times
          console.debug(
            `Document not found, retrying... Attempt ${retryCount + 1}`,
          );
          setTimeout(() => attemptUpdate(retryCount + 1), 1000); // Wait 1 second before retrying
        } else {
          console.error("Error updating user info:", error);
        }
      }
    };

    await attemptUpdate();
  };

  const getUserInfo = async (userId) => {
    const userRef = doc(db, "users", userId);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        console.debug("User data:", docSnap.data());
        return {
          dbEmail: docSnap.data().dbEmail,
          dbDisplayName: docSnap.data().dbDisplayName,
        };
      } else {
        console.debug("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting user info:", error);
      return null;
    }
  };

  const updateUserName = async (newDisplayName) => {
    if (auth.currentUser) {
      try {
        await updateProfile(auth.currentUser, {
          displayName: newDisplayName,
        });
        console.debug("Display name updated successfully to:", newDisplayName);
        // Update the local state to reflect the change
        setCurrentUser({
          ...auth.currentUser,
          displayName: newDisplayName,
        });
      } catch (error) {
        console.error("Error updating display name:", error);
      }
    } else {
      console.error(
        "No user is currently signed in, but trying to update display name",
      );
    }
  };

  const updateCurrentConversationId = async (userId, conversationId) => {
    const userRef = doc(db, "users", userId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        // Retrieve the existing array or initialize it if it doesn't exist
        let allStartedConversationIds =
          userDoc.data().allStartedConversationIds || [];
        let totalRoleplaysToday = userDoc.data().totalRoleplaysToday || 0;

        // Add the new conversationId if it's not already included
        if (!allStartedConversationIds.includes(conversationId)) {
          allStartedConversationIds.push(conversationId);
          totalRoleplaysToday += 1; // Increment the total roleplays today
        }
        // Update the document with the new currentConversationId and the updated array
        await updateDoc(userRef, {
          currentConversationId: conversationId,
          allStartedConversationIds: allStartedConversationIds,
          totalRoleplaysToday: totalRoleplaysToday,
          lastActive: new Date(),
        });
        console.debug(
          "Current conversation ID and all started conversation IDs updated successfully",
        );
      } else {
        console.debug("User document does not exist.");
      }
    } catch (error) {
      console.error(
        "Error updating current conversation ID and all started conversation IDs:",
        error,
      );
    }
  };

  const getCurrentConversationId = (userId) => {
    const userRef = doc(db, "users", userId);
    return getDoc(userRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          return docSnap.data().currentConversationId; // Return the conversation ID
        } else {
          throw new Error("Document for user " + userId + " does not exist");
        }
      })
      .catch((error) => {
        console.error("Error getting current conversation ID:", error);
        throw error; // Rethrow the error to be caught by the caller
      });
  };

  const finishConversation = async (userId, conversationId) => {
    const userRef = doc(db, "users", userId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        // Retrieve the existing array or initialize it if it doesn't exist
        let allFinishedConversationIds =
          userDoc.data().allFinishedConversationIds || [];
        // Add the new conversationId if it's not already included
        if (!allFinishedConversationIds.includes(conversationId)) {
          allFinishedConversationIds.push(conversationId);
        }
        // Update the document with the updated array
        await updateDoc(userRef, {
          allFinishedConversationIds: allFinishedConversationIds,
          lastActive: new Date(),
        });
        console.debug("All finished conversation IDs updated successfully");
      } else {
        console.debug("User document does not exist.");
      }
    } catch (error) {
      console.error("Error updating all finished conversation IDs:", error);
    }
  };

  const getTotalRoleplaysToday = async (userId) => {
    const userRef = doc(db, "users", userId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const lastActive = userData.lastActive.toDate(); // Convert Firestore Timestamp to JavaScript Date
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds for today

        // Check if last active is the same day as today
        if (lastActive.setHours(0, 0, 0, 0) === today.getTime()) {
          console.debug(
            "Returning today's roleplays:",
            userData.totalRoleplaysToday,
          );
          return userData.totalRoleplaysToday;
        } else {
          // Update the document if last active is not today
          await updateDoc(userRef, {
            totalRoleplaysToday: 0,
            lastActive: new Date(), // Update last active to now
          });
          console.debug(
            "Reset totalRoleplaysToday to 0 as last active was not today.",
          );
          return 0;
        }
      } else {
        console.debug("User document does not exist.");
        return 0;
      }
    } catch (error) {
      console.error("Error getting total roleplays today:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  const updateCustomPersona = async (userId, personaData) => {
    const userRef = doc(db, "users", userId);
    try {
      await updateDoc(userRef, {
        customPersona: personaData,
        hasCreatedABot: true,
        lastActive: new Date(),
      });
      console.debug("Custom persona updated successfully");
    } catch (error) {
      console.error("Error updating custom persona:", error);
      throw error;
    }
  };
  const value = {
    authenticatedCurrentUser: currentUser,
    authToken,
    signOut,
    authenticatedEmail: auth.currentUser ? auth.currentUser.email : null,
    authenticatedDisplayName: auth.currentUser
      ? auth.currentUser.displayName
      : null,
    orgId,
    updateUserInfo,
    getUserInfo,
    updateUserName,
    updateCurrentConversationId,
    getCurrentConversationId,
    finishConversation,
    getTotalRoleplaysToday,
    doc,
    onSnapshot,
    db,
    loadingAccountDetails,
    updateCustomPersona,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
