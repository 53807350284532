const ProcessAssessmentText = (data) => {
  // Ensure data is an array and not mistakenly treated as a string
  console.log("Received data:", data); // Temporarily log the data

  const flattenedData = data.flat();

  if (!Array.isArray(flattenedData)) {
    console.error("Expected an array of assessment data, received:", data);
    return [];
  }

  return flattenedData.map((item) => ({
    title: item.criteria_name,
    rating: `${item.criteria_score}/5`,
    description: item.criteria_description,
  }));
};

export default ProcessAssessmentText;
