// index.js
import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ErrorModal from "./components/BaseModal/ErrorModal";
import { createRoutes } from "./routes";

console.log("App loading started...");

const { routes } = createRoutes(); // Ensure you're extracting routes correctly
const router = createBrowserRouter(routes);

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorModal}
      onReset={() => {
        window.location.href = "/";
      }}
    >
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>,
  container,
);

serviceWorker.unregister();
