import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./services/firebase/AuthContext";
import { MaterialUIControllerProvider } from "context/materialUIController";
import { ModalProvider } from "context/modalContext";
import store from "./store";
import theme from "./assets/theme";

const ContextWrapper = ({ children }) => (
  <AuthProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MaterialUIControllerProvider>
          <ModalProvider>{children}</ModalProvider>
        </MaterialUIControllerProvider>
      </ThemeProvider>
    </Provider>
  </AuthProvider>
);

ContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextWrapper;
