/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";

import MKBox from "components/MKBox";
import WaveformPlayer from "./wavesurfer";

function PlaybackPlayerHolder({ url, timestamp }) {
  const theme = useTheme();
  // Calculate height using the theme's spacing scale
  // For example, theme.spacing(15) would be equivalent to 120px if the default spacing is 8px
  const calculatedHeight = theme.spacing(17); // Adjust the multiplier as needed
  return (
    <MKBox p={2} style={{ height: calculatedHeight }}>
      <WaveformPlayer url={url} timestamp={timestamp} />
    </MKBox>
  );
}

PlaybackPlayerHolder.propTypes = {
  url: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
};

export default PlaybackPlayerHolder;
