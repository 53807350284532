import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

const CrispChat = () => {
  const location = useLocation();
  const [showCrisp, setShowCrisp] = useState(false);
  const crispLoaded = useRef(false);

  const crispRoutes = [
    "/assessment",
    "/build-your-simulation",
    "/my-simulations",
    "/my-report",
    "/my-team",
  ];

  useEffect(() => {
    if (crispRoutes.includes(location.pathname)) {
      const timer = setTimeout(() => {
        setShowCrisp(true);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowCrisp(false);
    }
  }, [location]);

  useEffect(() => {
    const loadCrispScript = () => {
      if (!crispLoaded.current) {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "fecfdf47-22d6-41ea-a142-caf31c72b4fd";

        const d = document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);

        s.onload = () => {
          crispLoaded.current = true;
          window.$crisp.push(["do", "chat:show"]);
          window.$crisp.push(["do", "chat:close"]);
        };
      } else {
        window.$crisp.push(["do", "chat:show"]);
      }
    };

    const hideCrispWidget = () => {
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:hide"]);
      }
    };

    if (showCrisp) {
      loadCrispScript();
    } else {
      hideCrispWidget();
    }
  }, [showCrisp]);

  return null;
};

export default CrispChat;
