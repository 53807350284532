import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MKModal from "components/MKModal";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

const CongratsModal = ({ isAssessmentComplete, handleClose, totalScore }) => {
  const selectedRoleplay = useSelector((state) => state.user.selectedRoleplay);
  const navigate = useNavigate();

  const isOpen =
    isAssessmentComplete &&
    selectedRoleplay === "cold_call_saastock_competition";

  const hasQualified = totalScore >= 12;

  const handleTryAgain = () => {
    handleClose();
    navigate("/callview");
  };

  return (
    <MKModal open={isOpen} handleClose={handleClose} canClose={true}>
      <MKBox textAlign="center">
        <MKTypography
          variant="h4"
          mb={2}
          color={hasQualified ? "success" : "error"}
        >
          {hasQualified ? "Congratulations!" : "Nice try!"}
        </MKTypography>
        <MKTypography variant="h5" mb={3}>
          You scored {totalScore}/20
        </MKTypography>
        <MKTypography variant="body2" mb={4}>
          {hasQualified
            ? "Great job! You've qualified for the 500€ prize. Each attempt above 12 counts, so feel free to try again and improve your score!"
            : "You need a score of 12 or higher to qualify for the 500€ prize. Don't worry, you can try again!"}
        </MKTypography>
        <MKBox display="flex" justifyContent="space-around">
          <MKButton onClick={handleClose} color="secondary">
            Understand your score
          </MKButton>
          <MKButton onClick={handleTryAgain} color="primary">
            Try Again
          </MKButton>
        </MKBox>
      </MKBox>
    </MKModal>
  );
};

CongratsModal.propTypes = {
  isAssessmentComplete: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  totalScore: PropTypes.number.isRequired,
};

export default CongratsModal;
