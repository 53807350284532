// This is for 1 button - Confirm
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import BaseModal from "../BaseModal/BaseModal";

let openModalFunction = null;
let modalProps = {};

const InformationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    if (modalProps.navigateTo) {
      navigate(modalProps.navigateTo);
    }
  };

  const handleOpen = (props) => {
    modalProps = props;
    setIsOpen(true);
  };

  // Storing handleOpen for external use
  openModalFunction = handleOpen;

  // Split the content by new lines and map to paragraphs if it's a string
  const renderContent = () => {
    if (typeof modalProps.content === "string") {
      return modalProps.content
        .split("\n")
        .map((line, index) => <p key={index}>{line}</p>);
    }
    return modalProps.content; // Return as is if not a string
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title={modalProps.title || ""}
      onClose={handleClose}
      actions={[{ label: "Close", onClick: handleClose }]}
    >
      {renderContent()}
    </BaseModal>
  );
};

export const openInformationModal = (props) => {
  if (openModalFunction) {
    openModalFunction(props);
  }
};

export default InformationModal;
