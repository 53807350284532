import { useEffect, useCallback, useState } from "react";
import { useBlocker } from "react-router-dom";
import { useModal } from "context/modalContext";

const useNavigationPrompt = (shouldPrompt) => {
  const { openModal, closeModal } = useModal();
  const [isShowingModal, setIsShowingModal] = useState(false);

  const handleBeforeUnload = useCallback(
    (event) => {
      if (shouldPrompt) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    [shouldPrompt],
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  const blockerFunction = useCallback(
    ({ currentLocation, nextLocation }) => {
      return shouldPrompt && currentLocation.pathname !== nextLocation.pathname;
    },
    [shouldPrompt],
  );

  const blocker = useBlocker(blockerFunction);

  useEffect(() => {
    console.log("Blocker state in useNavigationPrompt:", blocker.state);

    if (blocker.state === "blocked" && !isShowingModal) {
      setIsShowingModal(true);
      openModal({
        title: "Leave Page",
        text_in_modal:
          "Are you sure you want to leave this page? Your progress may be lost.",
        onConfirm: () => {
          setIsShowingModal(false);
          closeModal();
          blocker.proceed();
        },
        onCancel: () => {
          setIsShowingModal(false);
          closeModal();
          blocker.reset();
        },
      });
    }
  }, [blocker, openModal, closeModal, isShowingModal]);

  return { blocker };
};

export default useNavigationPrompt;
