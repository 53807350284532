// store.js
import { configureStore } from "@reduxjs/toolkit";
import callViewStateReducer from "./features/callView/callViewSlice";
import userReducer from "./features/user/userSlice";
import roleplayConfigDynamicReducer from "./features/roleplayConfigDynamic/roleplayConfigDynamicSlice";
import socketConfigDynamicReducer from "./features/socketConfigDynamic/socketConfigDynamicSlice";
import loadingReducer from "./features/loading/loadingSlice";
import websiteAnalysisReducer from "./features/websiteAnalysis/websiteAnalysisSlice";

export const store = configureStore({
  reducer: {
    callViewState: callViewStateReducer,
    user: userReducer,
    roleplayConfigDynamic: roleplayConfigDynamicReducer,
    socketConfigDynamic: socketConfigDynamicReducer,
    loading: loadingReducer,
    websiteAnalysis: websiteAnalysisReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
