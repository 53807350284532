// useUserManagement.js
import { useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import LogRocket from "logrocket";

import {
  setAuthLevel,
  setDisplayName,
  setEmail,
  setSelectedRoleplay,
  setAuthenticated,
  setTotalRoleplaysToday,
  setOrgId,
  setCustomPersona,
  setFreeRoleplaysRemaining,
  clearCustomPersona,
} from "../features/user/userSlice";
import { AuthContext } from "../services/firebase/AuthContext";
import { setLoading } from "../features/loading/loadingSlice";
import { ORG_SETTINGS } from "../config/orgConfig";

export const useUserManagement = () => {
  const {
    authenticatedCurrentUser,
    authToken,
    authenticatedEmail,
    authenticatedDisplayName,
    orgId,
    getTotalRoleplaysToday,
    doc,
    onSnapshot,
    db,
    loadingAccountDetails,
  } = useContext(AuthContext);

  const enterNameModalRef = useRef(null);

  const dispatch = useDispatch();

  // Once we are logged in, we start tracking the user
  useEffect(() => {
    if (authenticatedEmail && authenticatedCurrentUser) {
      if (process.env.REACT_APP_ENV !== "development") {
        LogRocket.identify(authenticatedCurrentUser.uid, {
          name: authenticatedDisplayName,
          email: authenticatedEmail,
        });
      }

      localStorage.setItem("authToken", authToken);
      console.debug("User management hook triggered for user");
      dispatch(setAuthenticated(true));
      dispatch(setEmail(authenticatedEmail));

      //if a user has a display name, we send it, otherwise we prompt them to add one
      if (authenticatedDisplayName) {
        dispatch(setDisplayName(authenticatedDisplayName));
      } else {
        console.log("Opening enter name modal");
        enterNameModalRef.current.open();
      }

      let authLevel = "general";
      if (orgId && ORG_SETTINGS[orgId]) {
        // Set auth level and roleplay based on orgId
        authLevel = ORG_SETTINGS[orgId].authLevel;
        dispatch(setSelectedRoleplay(ORG_SETTINGS[orgId].defaultRoleplay));
      }

      dispatch(setAuthLevel(authLevel));
      dispatch(setOrgId(orgId));
    } else {
      //if you log out, before logging in again, we need to reset the user
      localStorage.removeItem("authToken");
      dispatch(setAuthenticated(false));
      dispatch(setEmail("demo@example.com"));
      dispatch(setDisplayName("Unknown"));
      // dispatch(setSelectedRoleplay("cold_call_jimmy_easy"));
      dispatch(setAuthLevel("general"));
      dispatch(setTotalRoleplaysToday(0));
      dispatch(setOrgId(null));
      dispatch(clearCustomPersona());
    }
  }, [authenticatedCurrentUser, dispatch, authenticatedEmail]);

  useEffect(() => {
    dispatch(setLoading(loadingAccountDetails));
  }, [loadingAccountDetails, dispatch]);

  useEffect(() => {
    const fetchTotalRoleplays = async () => {
      if (authenticatedCurrentUser) {
        //check and reset the number of roleplays today
        await getTotalRoleplaysToday(authenticatedCurrentUser.uid);
      }
    };

    fetchTotalRoleplays();
  }, [authenticatedCurrentUser, dispatch]);

  useEffect(() => {
    if (!authenticatedCurrentUser) return;
    //listener for changing and reseting the number of roleplays today

    const userRef = doc(db, "users", authenticatedCurrentUser.uid);
    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const totalRoleplays = data.totalRoleplaysToday || 0;
          dispatch(setTotalRoleplaysToday(totalRoleplays));
          dispatch(setFreeRoleplaysRemaining(data.freeRoleplaysRemaining));
          if (data.customPersona) {
            dispatch(setCustomPersona(data.customPersona));
          }
        }
      },
      (error) => {
        console.error("Error fetching user data:", error);
        // Optionally, handle the error more robustly here, e.g., by setting an error state,
        // showing a notification to the user, or retrying the subscription.
      },
    );

    return () => unsubscribe(); // Cleanup subscription on unmount or when authenticatedCurrentUser changes
  }, [authenticatedCurrentUser?.uid, dispatch]);
  return { authenticatedCurrentUser, enterNameModalRef };
};
