import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MKButton from "../../components/MKButton";
import MKBox from "components/MKBox";

const NavigationButtons = ({ increaseRank }) => {
  return (
    <MKBox
      mt={4}
      width={{ xs: "90%", sm: "80%", md: "70%" }}
      maxWidth={{ sm: "500px", md: "1000px" }}
    >
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <MKButton
            component={Link}
            to="/callview"
            variant="contained"
            color="secondary"
          >
            Restart
          </MKButton>
        </Grid>
        <Grid item>
          <MKButton
            component={Link}
            to="/callview"
            variant="contained"
            color="primary"
            onClick={increaseRank}
          >
            Next Level
          </MKButton>
        </Grid>
      </Grid>
    </MKBox>
  );
};

NavigationButtons.propTypes = {
  increaseRank: PropTypes.func.isRequired,
};

export default NavigationButtons;
