import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PlaybackPlayerHolder from "components/PlaybackPlayerHolder";
import MKButton from "components/MKButton";
import { Grid } from "@mui/material"; // Added import for Grid
import { motion, AnimatePresence } from "framer-motion"; // Added imports for motion and AnimatePresence
import Icon from "@mui/material/Icon"; // Added import for Icon

const CallOfTheWeek = React.memo(({ bestCall }) => {
  const [showPlayerCard, setShowPlayerCard] = useState(false);

  const handleShowPlayerCard = useCallback(() => {
    setShowPlayerCard((current) => !current);
  }, []);

  return (
    <MKBox pb={6} bgColor="white" borderRadius="lg" shadow="xs">
      <MKBox p={3}>
        <MKTypography variant="h5">Call of the week</MKTypography>
      </MKBox>
      <MKBox
        mx={2}
        py={3}
        mb={-3}
        px={2}
        bgColor="secondary"
        borderRadius="lg"
        shadow="sm"
        style={{
          position: "relative",
          cursor: "pointer",
        }}
        onClick={handleShowPlayerCard}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <MKTypography variant="h6" color="dark">
              {bestCall?.name}
            </MKTypography>
          </Grid>
          <Grid item>
            <MKTypography variant="h6" color="dark">
              Score: {bestCall?.average_score}
            </MKTypography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <MKTypography variant="h6" color="dark">
                  Best Area: {bestCall?.strongest_criteria}
                </MKTypography>
              </Grid>
              <Grid item>
                <MKButton variant="text" color="dark" iconOnly={true}>
                  <Icon>{showPlayerCard ? "expand_less" : "expand_more"}</Icon>
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MKBox>
      <AnimatePresence>
        {showPlayerCard && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <MKBox pt={3}>
              <PlaybackPlayerHolder url={bestCall?.call_recording_url} />
            </MKBox>
          </motion.div>
        )}
      </AnimatePresence>
    </MKBox>
  );
});

CallOfTheWeek.displayName = "CallOfTheWeek";

CallOfTheWeek.propTypes = {
  bestCall: PropTypes.shape({
    name: PropTypes.string,
    average_score: PropTypes.number,
    strongest_criteria: PropTypes.string,
    call_recording_url: PropTypes.string,
  }),
};

export default CallOfTheWeek;
