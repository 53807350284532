import React from "react";
import PropTypes from "prop-types";
import TextSubmitModal from "components/BaseModal/TextSubmitModal";

const CompanyNameInputModal = ({ isOpen, onClose, onSubmit }) => {
  const handleSubmit = (inputValues) => {
    const companyName = inputValues.companyName.trim();
    if (companyName) {
      onSubmit(companyName);
    }
  };

  return (
    <TextSubmitModal
      isOpen={isOpen}
      title="Enter Company Name"
      onClose={onClose}
      actions={[
        {
          label: "Submit and Start the Call",
          onClick: handleSubmit,
          highlight: true,
        },
      ]}
      inputs={[
        {
          name: "companyName",
          placeholder: "Enter company name",
          type: "name",
          requireInput: true,
        },
      ]}
      additionalContent={<p>Please enter the name of the company:</p>}
    />
  );
};

CompanyNameInputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyNameInputModal;
