const validationRules = {
  fullName: {
    pattern: /^[\p{L}\s\-']*$/u,
    maxLength: 100,
    errorMessage:
      "Please enter a valid name (letters, spaces, hyphens, and apostrophes only)",
    transform: (value) =>
      value
        .replace(/[^\p{L}\s\-']/gu, "")
        .replace(/\s+/g, " ")
        .trim(),
  },

  title: {
    pattern: /^[\p{L}\s\-'.()]*$/u,
    maxLength: 50,
    errorMessage: "Please enter a valid title",
    transform: (value) =>
      value
        .replace(/[^\p{L}\s\-'.()]/gu, "")
        .replace(/\s+/g, " ")
        .trim(),
  },

  age: {
    pattern: /^[0-9]{1,3}$/,
    validate: (value) => {
      const num = parseInt(value);
      return num >= 18 && num <= 120;
    },
    errorMessage: "Age must be between 18 and 120",
    transform: (value) => value.replace(/[^0-9]/g, ""),
  },

  location: {
    pattern: /^[A-Za-z0-9\s,.-]+$/,
    maxLength: 100,
    errorMessage: "Please enter a valid location",
    transform: (value) =>
      value
        .replace(/[^A-Za-z0-9\s,.-]/g, " ")
        .replace(/\s+/g, " ")
        .trim(),
  },

  companyName: {
    pattern: /^[A-Za-z0-9\s&,.-]+$/,
    maxLength: 100,
    errorMessage: "Please enter a valid company name",
    transform: (value) =>
      value
        .replace(/[^A-Za-z0-9\s&,.-]/g, " ")
        .replace(/\s+/g, " ")
        .trim(),
  },

  numberEmployees: {
    pattern: /^[0-9,+\s]+$/,
    maxLength: 20,
    errorMessage: "Please enter a valid number (e.g., 100 or 1,000+)",
    transform: (value) =>
      value
        .replace(/[^0-9,+\s]/g, "")
        .replace(/\s+/g, " ")
        .trim(),
  },

  description: {
    maxLength: 1000,
    errorMessage: "Maximum 1000 characters allowed",
    transform: (value) => value.replace(/\s+/g, " ").trim(),
  },

  challenge: {
    maxLength: 500,
    errorMessage: "Maximum 500 characters allowed",
    transform: (value) => value.replace(/\s+/g, " ").trim(),
  },
};

export default validationRules;
