import React, { useState } from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function EditableField({
  label,
  value,
  onChange,
  multiline,
  rows,
  validationType,
  validationRules,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [isLocalEditing, setIsLocalEditing] = useState(false);
  const [error, setError] = useState("");

  const validateValue = (newValue) => {
    if (!validationRules || !validationType) return true;

    const rules = validationRules[validationType];
    if (!rules) return true;

    if (rules.maxLength && newValue.length > rules.maxLength) {
      setError(`Maximum ${rules.maxLength} characters allowed`);
      return false;
    }

    if (rules.pattern && !rules.pattern.test(newValue)) {
      setError(rules.errorMessage);
      return false;
    }

    if (rules.validate && !rules.validate(newValue)) {
      setError(rules.errorMessage);
      return false;
    }

    setError("");
    return true;
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    validateValue(newValue);
  };

  const handleSave = () => {
    if (validationRules && validationType) {
      const rules = validationRules[validationType];
      if (rules.transform) {
        const transformedValue = rules.transform(localValue);
        if (validateValue(transformedValue)) {
          onChange(transformedValue);
          setLocalValue(transformedValue);
          setIsLocalEditing(false);
          return;
        }
      }
    }

    if (validateValue(localValue)) {
      onChange(localValue);
      setIsLocalEditing(false);
    }
  };

  const handleCancel = () => {
    setLocalValue(value);
    setIsLocalEditing(false);
  };

  return (
    <MKBox mb={2}>
      <MKBox
        display="flex"
        alignItems={multiline ? "flex-start" : "center"}
        gap={2}
      >
        {label && (
          <MKTypography
            variant="h6"
            sx={{
              minWidth: "100px",
              fontSize: "1rem",
              mt: multiline ? 1 : 0,
            }}
          >
            {label}
          </MKTypography>
        )}
        {!isLocalEditing ? (
          <MKBox position="relative" flexGrow={1}>
            <MKBox display="flex" pr={5}>
              <MKTypography
                variant="body2"
                color="text.secondary"
                sx={{ whiteSpace: multiline ? "pre-wrap" : "normal" }}
              >
                {value}
              </MKTypography>
            </MKBox>
            <IconButton
              size="small"
              onClick={() => setIsLocalEditing(true)}
              sx={{
                position: "absolute",
                right: 0,
                top: multiline ? 0 : "50%",
                transform: multiline ? "none" : "translateY(-50%)",
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </MKBox>
        ) : (
          <MKBox display="flex" alignItems="flex-start" gap={1} flexGrow={1}>
            <MKInput
              multiline={multiline}
              rows={rows || 1}
              variant="outlined"
              value={localValue}
              onChange={handleChange}
              fullWidth
            />
            <MKBox>
              <IconButton size="small" onClick={handleSave}>
                <CheckIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleCancel}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </MKBox>
          </MKBox>
        )}
      </MKBox>
      {error && (
        <MKTypography
          variant="caption"
          color="error"
          sx={{ mt: 1, display: "block" }}
        >
          {error}
        </MKTypography>
      )}
    </MKBox>
  );
}

EditableField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  validationType: PropTypes.string,
  validationRules: PropTypes.object,
};

EditableField.defaultProps = {
  multiline: false,
  rows: 1,
};

export default EditableField;
