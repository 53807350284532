// HttpService.js
class HttpService {
  constructor(baseUrl, interceptors = []) {
    this.baseUrl = baseUrl;
    this.interceptors = interceptors;
  }

  async fetchWithInterceptors(url, options) {
    // Apply request interceptors
    const finalOptions = this.interceptors.reduce(
      (opts, interceptor) => interceptor(opts),
      options,
    );

    const response = await fetch(url, finalOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

  async performPostRequest(endpoint, data) {
    const requestUrl = `${this.baseUrl}/${endpoint}`;
    return this.fetchWithInterceptors(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  async performPostRequestStream(endpoint, data) {
    const requestUrl = `${this.baseUrl}/${endpoint}`;
    return this.fetchWithInterceptors(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.body);
  }

  async performGetRequest(endpoint) {
    const requestUrl = `${this.baseUrl}/${endpoint}`;
    return this.fetchWithInterceptors(requestUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }
}

const authInterceptor = (options) => {
  const token = localStorage.getItem("authToken");
  const newOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  };
  console.debug("Final request options:", newOptions);
  return newOptions;
};

// Initialize with your base URL
const httpService = new HttpService(process.env.REACT_APP_HTTP_URL, [
  authInterceptor,
]);

export default httpService;
