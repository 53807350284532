import React from "react";
import PropTypes from "prop-types";
import { keyframes } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MKBox from "components/MKBox"; // Import MKBox from your components

const waveAnimation = keyframes`
  0% { height: 10px; transform: translateY(0px); background: #356782; }
  25% { height: 40px; transform: translateY(-5px); background: #417B9C; }
  50% { height: 10px; transform: translateY(0px); background: #5A9CB3; }
  100% { height: 10px; transform: translateY(0px); background: #8BD3E6; }
`;

const SoundWave = ({ isPlaying, boxCount }) => {
  const matches = useMediaQuery("(min-width:600px)");

  // Function to generate a random duration between a range
  const randomDuration = () => Math.random() * 0.5 + 1.1; // Between 1.8s and 2.3s

  const boxes = Array.from(
    { length: matches ? boxCount : Math.floor(boxCount / 2) },
    (_, i) => (
      <MKBox
        key={i}
        bgColor="secondary"
        sx={{
          height: "1.125rem", // Use rem instead of px
          width: "0.625rem", // Use rem instead of px
          borderRadius: "0.5rem", // Use rem instead of px
          //   background: "#BEC5D9",
          animation: `${waveAnimation} ${randomDuration()}s infinite ease-in-out`,
          animationPlayState: isPlaying ? "running" : "paused",
          animationDelay: `${i * 0.1}s`, // Adjust the delay as needed
          mr: 0.5, // Use the `mr` prop for margin-right with theme spacing
        }}
      />
    ),
  );

  return (
    <MKBox display="flex" justifyContent="center" alignItems="center">
      {boxes}
    </MKBox>
  );
};

SoundWave.propTypes = {
  isPlaying: PropTypes.bool.isRequired, // Add PropTypes validation for isPlaying
  boxCount: PropTypes.number.isRequired, // Assuming you also want to validate boxCount
};

export default SoundWave;
