import React from "react";
import { useSelector } from "react-redux";
import { DotLottiePlayer } from "@dotlottie/react-player";
import Backdrop from "@mui/material/Backdrop";

// Import your .lottie file
import loadingAnimation from "assets/animations/loading_new_colour.lottie";

const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <DotLottiePlayer
        src={loadingAnimation}
        autoplay
        loop
        style={{ width: 200, height: 200 }} // Adjust size as needed
      />
    </Backdrop>
  );
};

export default LoadingOverlay;
