/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef, useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKButton
import MKButtonRoot from "components/MKButton/MKButtonRoot";

const MKButton = forwardRef(
  (
    { color, variant, size, circular, iconOnly, children, isPulsing, ...rest },
    ref,
  ) => {
    const [pulse, setPulse] = useState(false);

    useEffect(() => {
      let interval;
      if (isPulsing) {
        interval = setInterval(() => {
          setPulse((prev) => !prev);
        }, 500); // Adjust the interval as needed for the pulsing effect
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [isPulsing]);

    return (
      <MKButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly }}
        sx={{
          transform: pulse ? "scale(1.5)" : "scale(1)",
          transition: "transform 1s linear", // Adjust timing as needed
          ...(isPulsing ? { animation: "pulse 1s infinite linear" } : {}),
        }}
      >
        {children}
      </MKButtonRoot>
    );
  },
);

// Setting default values for the props of MKButton
MKButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
  isPulsing: false,
};

// Typechecking props for the MKButton
MKButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "default",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  isPulsing: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

MKButton.displayName = "MKButton";

export default MKButton;
