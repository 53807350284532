/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// @mui material components
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ReplayIcon from "@mui/icons-material/Replay";
import PersonIcon from "@mui/icons-material/Person";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";

import SoundWave from "./SoundWave";
import { openInformationModal } from "components/BaseModal/InformationModal";
import InformationModal from "components/BaseModal/InformationModal";
import useRecordingButton from "hooks/CallView/useRecordingButton";
import FeedbackWithTextSubmitModal from "components/BaseModal/FeedbackWithTextSubmitModal";
import AudioVisualizer from "components/AudioVisualizer/AudioVisualizer";
import AvatarQuestionMark from "assets/images/avatars/question_mark_avatar_1.png";

function CallViewCard({
  callHasStarted,
  handleRestart,
  isExerciseEndModalOpen,
  goToAssessment,
  processFeedback,
  volumeLevel,
}) {
  const theme = useTheme();

  const callState = useSelector((state) => state.callViewState.callState);

  //recording button
  const { buttonColor } = useRecordingButton(callState);

  const roleplayConfigDynamic = useSelector(
    (state) => state.roleplayConfigDynamic.data,
  );

  const [avatarSrc, setAvatarSrc] = useState(AvatarQuestionMark);

  useEffect(() => {
    if (roleplayConfigDynamic.avatar) {
      setAvatarSrc(roleplayConfigDynamic.avatar);
    }
  }, [roleplayConfigDynamic.avatar]);

  return (
    <MKBox
      component="section"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        py: { xs: 1, sm: 2, md: 2.5, lg: 3, xl: 3.5 },
      }}
    >
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ gap: { xs: 2, sm: 3, md: 4, lg: 5, xl: 6 } }}>
          <MKBox
            position="relative"
            borderRadius="lg"
            mx={{ xs: 0, sm: 0, md: 5 }}
            p={5}
            sx={{
              height: "40vh",
              maxWidth: "100%",
              "& img": {
                height: "100%",
                width: "auto",
                maxHeight: "35vh",
                [theme.breakpoints.up("xl")]: {
                  maxHeight: "40vh",
                },
              },
            }}
          >
            <MKBox
              position="relative"
              borderRadius="50%"
              overflow="visible" // Ensure overflow is hidden to contain the visualizer
              width="200px" // Match the "enormous" avatar size
              height="200px"
              display="flex" // Use flex to center the contents
              justifyContent="center" // Center horizontally
              alignItems="center" // Center vertically
            >
              <AudioVisualizer volumeRef={volumeLevel} size="300px" />
              <MKAvatar
                src={avatarSrc}
                alt="Avatar"
                size="enormous"
                shadow="md"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              />
            </MKBox>
          </MKBox>
          <MKBox height="5vh">
            <SoundWave isPlaying={callHasStarted} boxCount={6} />
          </MKBox>

          <MKBox mb={4} p={3} textAlign="center">
            <MKBox display="flex" justifyContent="center" gap={3}>
              <MKButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  openInformationModal({
                    title: "Cheet Sheet",
                    content: roleplayConfigDynamic.company_details,
                  })
                }
                iconOnly
                circular
              >
                <TextSnippetIcon />
              </MKButton>
              <MKButton
                variant="contained"
                color={buttonColor}
                size="large"
                iconOnly
                circular
                onClick={() =>
                  openInformationModal({
                    title: roleplayConfigDynamic.assistant_name,
                    content: roleplayConfigDynamic.call_instructions ? (
                      roleplayConfigDynamic.call_instructions
                        .split("\n")
                        .map((instruction, index) => (
                          <p key={index}>{instruction}</p>
                        ))
                    ) : (
                      <p>Instructions have not loaded.</p>
                    ),
                  })
                }
              >
                <PersonIcon />
              </MKButton>
              <MKButton
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleRestart(true)}
                iconOnly
                circular
              >
                <ReplayIcon />
              </MKButton>
            </MKBox>
          </MKBox>
        </Card>
      </Container>
      <InformationModal />
      <FeedbackWithTextSubmitModal
        isOpen={isExerciseEndModalOpen}
        title="End of the roleplay!"
        onClose={processFeedback}
        actions={[
          { label: "Restart", onClick: () => handleRestart(false) },
          {
            label: "Submit and get feedback",
            onClick: goToAssessment,
            highlight: true,
          },
        ]}
        inputs={[
          {
            name: "feedbackText",
            type: "text",
            requireInput: false,
            minRows: 6,
            placeholder: "Tell us what you think about the call...",
          },
        ]}
      />
    </MKBox>
  );
}

CallViewCard.propTypes = {
  callHasStarted: PropTypes.bool,
  handleRestart: PropTypes.func.isRequired,
  isExerciseEndModalOpen: PropTypes.bool.isRequired,
  goToAssessment: PropTypes.func.isRequired,
  processFeedback: PropTypes.func.isRequired,
  volumeLevel: PropTypes.shape({
    current: PropTypes.number,
  }),
};

export default CallViewCard;
