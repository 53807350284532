/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MKBox from "components/MKBox"; // Updated import
import MKTypography from "components/MKTypography"; // Updated import
import MKButton from "components/MKButton";
import MKAvatar from "components/MKAvatar";
import BaseModal from "components/BaseModal/BaseModal";
import { setSelectedRoleplay } from "features/user/userSlice";

function RoleplayCard({
  title,
  description,
  duedate,
  avatars,
  selectedRoleplay,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (selectedRoleplay) {
      dispatch(setSelectedRoleplay(selectedRoleplay));
      navigate("/callview");
    } else {
      setIsModalOpen(true); // Open the modal if no roleplay number provided
    }
  };
  return (
    <>
      <MKBox mb={1.5}>
        <Card>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <MKTypography
                variant="h5"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    whiteSpace: "normal",
                  },
                }}
              >
                {title}
              </MKTypography>
              <MKBox display="flex" pt={1}>
                {avatars.map((avatar, index) => (
                  <MKAvatar
                    key={index}
                    src={avatar.src}
                    alt={`Avatar ${index}`}
                    size="xs"
                    sx={{
                      marginLeft: index === 0 ? 0 : -1, // Negative margin for overlap, except for the first avatar
                      zIndex: avatars.length + index, // Ensures avatars overlap correctly (leftmost on top)
                      border: "2px solid white", // Adds a white border around each avatar
                    }}
                  />
                ))}
              </MKBox>
            </Grid>
            <Grid item xs={6}>
              <MKBox display="flex" justifyContent="flex-end" width="100%">
                <MKButton
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  Start
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
          <MKBox textAlign="left" lineHeight={1.25} minHeight="3rem" px={2}>
            <MKTypography variant="button">{description}</MKTypography>
          </MKBox>
          <Divider />
          <MKBox
            pb={2}
            px={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MKBox display="flex" flexDirection="column" alignItems="center">
              <MKTypography component="p" variant="button" color="text">
                <MKTypography
                  component="span"
                  variant="button"
                  fontWeight="bold"
                >
                  {duedate.date}
                </MKTypography>
              </MKTypography>
              <MKTypography component="p" variant="button" color="text">
                {duedate.text}
              </MKTypography>
            </MKBox>
          </MKBox>
        </Card>
      </MKBox>
      <BaseModal
        isOpen={isModalOpen}
        title="We recorded your vote!"
        onClose={() => setIsModalOpen(false)}
        actions={[
          { label: "Close", onClick: () => setIsModalOpen(false) },
          {
            label: "Book a demo",
            onClick: () => window.open("https://calendly.com/ivelin-pxm/15min"),
            highlight: true,
          },
        ]}
      >
        <p>You currently don&apos;t have access to this scenario.</p>
        <p>But we recorded your vote.</p>
        <p>If this scenario gets the most votes we will release this next.</p>
      </BaseModal>
    </>
  );
}

// Setting default values for the props of ComplexStatisticsCard
RoleplayCard.defaultProps = {
  color: "primary",
  duedate: {
    date: "No",
    text: "Scoring",
  },
  avatars: [],
  selectedRoleplay: null,
};

// Typechecking props for the ComplexStatisticsCard
RoleplayCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duedate: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  }),
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ),
  selectedRoleplay: PropTypes.string,
};

export default RoleplayCard;
