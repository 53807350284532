import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    title: "",
    text_in_modal: "",
    onConfirm: null,
    onCancel: null,
  });

  const openModal = (props) => {
    setModalProps({ ...modalProps, ...props, isOpen: true });
  };

  const closeModal = () => {
    setModalProps({ isOpen: false });
  };

  return (
    <ModalContext.Provider value={{ modalProps, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
