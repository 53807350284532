import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import DashboardLayout from "layouts/DashboardLayout";
import RoleplayCard from "components/RoleplayCard";
import AvatarQuestionMark from "assets/images/avatars/question_mark_avatar_1.png";
// import SkylarBanner from "assets/images/banners/skylar_banner.jpg";
import SkylarBanner from "assets/images/background/new_banner_website_dust_with_logo_0211.jpg";
import scenarioCards from "config/scenarioConfig";
import SignUpModal from "components/SignUpModal";
import BackgroundVideo from "components/BackgroundVideo/BackgroundVideo";

import {
  nameCardVariants,
  imageVariants,
  categoryVariants,
  cardVariants,
  useAnimationLogic,
} from "./animationUtils";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function groupCardsByCategory(cards) {
  const grouped = {};
  cards.forEach((card) => {
    card.categories.forEach((category) => {
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(card);
    });
  });
  return grouped;
}

function adjustCategoriesForAuthLevel(cards, authLevel) {
  return cards.map((card) => {
    let categories = card.categories;
    if (authLevel === "multiverse" && card.multiverseCategories) {
      categories = [...card.multiverseCategories];
    }
    if (authLevel === "PayPro Global" && card.payproCategories) {
      categories = [...card.payproCategories];
    }
    return { ...card, categories };
  });
}

function createCustomBotCard(customPersona) {
  return {
    color: "primary",
    title: `${customPersona.name || "My Bot"}`,
    description: `Practice calls with your custom persona`,
    duedate: {
      date: "New",
      text: "Custom Scenario",
    },
    avatars: [customPersona.avatarUrl || AvatarQuestionMark],
    selectedRoleplay: "custom_bot",
    categories: ["Recommended for you"],
    authLevels: ["general"],
  };
}

function MySimulations() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const signUpModalRef = useRef(null);

  // Access user data from Redux store
  const {
    isAuthenticated,
    displayName,
    authLevel,
    freeRoleplaysRemaining,
    hasCreatedABot,
    customPersona,
  } = useSelector((state) => state.user);

  // Adjusting cards based on auth level
  const adjustedCards = adjustCategoriesForAuthLevel(scenarioCards, authLevel);
  let filteredCards = adjustedCards.filter((card) =>
    card.authLevels.includes(authLevel),
  );

  // Add custom bot card if user has created one
  if (hasCreatedABot && customPersona) {
    const customBotCard = createCustomBotCard(customPersona);
    filteredCards = [customBotCard, ...filteredCards];
  }

  const groupedCards = groupCardsByCategory(filteredCards);

  const swiperSettings = {
    modules: [Pagination],
    pagination: { clickable: true },
    spaceBetween: 20,
    slidesPerView: isSmallScreen ? 1.2 : Math.min(3.2, filteredCards.length),
    speed: 500,
  };

  const videoBackground =
    "https://storage.googleapis.com/skylar_public_assets/background_videos/new%20banner%20website%20-%20mysimulations%20-%20skylar%20(2).mp4";

  const { showVideo, categoryControls, cardControls, handleAnimationComplete } =
    useAnimationLogic();

  return (
    <DashboardLayout>
      <MKBox>
        <MKBox position="relative" mb={5}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={imageVariants}
          >
            <MKBox
              display="flex"
              alignItems="center"
              position="relative"
              minHeight="12.5rem"
              borderRadius="xl"
              sx={{
                overflow: "hidden",
              }}
            >
              {showVideo ? (
                <BackgroundVideo
                  videoSrc={videoBackground}
                  placeholderSrc={SkylarBanner}
                  showFilter={false}
                />
              ) : (
                <MKBox
                  sx={{
                    backgroundImage: `url(${SkylarBanner})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
            </MKBox>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={nameCardVariants}
            onAnimationComplete={handleAnimationComplete}
          >
            <Card
              sx={{
                position: "relative",
                mt: -6,
                mx: 3,
                py: 1.5,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MKAvatar
                    src={AvatarQuestionMark}
                    alt="profile-image"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <MKBox height="100%" mt={0.5} lineHeight={1}>
                    <MKTypography variant="h5" fontWeight="medium">
                      {isAuthenticated ? displayName : "Your Name Here"}
                    </MKTypography>
                    {isAuthenticated ? (
                      <MKTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        {authLevel === "general"
                          ? `Free Roleplays Remaining: ${freeRoleplaysRemaining}`
                          : authLevel.charAt(0).toUpperCase() +
                            authLevel.slice(1)}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        variant="button"
                        color="primary"
                        fontWeight="regular"
                        onClick={() => signUpModalRef.current.open()}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Register to get started
                      </MKTypography>
                    )}
                  </MKBox>
                </Grid>
              </Grid>
            </Card>
          </motion.div>
        </MKBox>
        <Container>
          {Object.keys(groupedCards).map((category, categoryIndex) => (
            <MKBox key={category} mb={3}>
              <motion.div
                initial="hidden"
                animate={categoryControls}
                variants={categoryVariants}
              >
                <MKTypography variant="h3" mb={2} pt={2} pb={3}>
                  {category}
                </MKTypography>
              </motion.div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Swiper {...swiperSettings}>
                    {groupedCards[category].map((card, cardIndex) => (
                      <SwiperSlide key={card.title}>
                        <motion.div
                          custom={
                            categoryIndex * groupedCards[category].length +
                            cardIndex
                          }
                          initial="hidden"
                          animate={cardControls}
                          variants={cardVariants}
                        >
                          <RoleplayCard
                            color={card.color}
                            title={card.title}
                            description={card.description}
                            percentage={card.percentage}
                            duedate={card.duedate}
                            avatars={card.avatars.map((avatar) => ({
                              src: avatar,
                            }))}
                            selectedRoleplay={card.selectedRoleplay}
                          />
                        </motion.div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3 }} />
            </MKBox>
          ))}
        </Container>
        <SignUpModal ref={signUpModalRef} />
      </MKBox>
    </DashboardLayout>
  );
}

export default MySimulations;
