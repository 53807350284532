import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { FormControl, Select, MenuItem } from "@mui/material";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import EditableField from "components/EditableField";

import validationRules from "utils/validationRules";
import LoadingSkeleton from "components/LoadingSkeleton";

const MotionGrid = motion(Grid);

function StepThree({ onSubmit, persona }) {
  const [modifications, setModifications] = useState({});
  const [callType, setCallType] = useState("cold_call");
  const [callDifficulty, setCallDifficulty] = useState("easy");

  const analysisData = useSelector(
    (state) => state.websiteAnalysis.analysisData,
  );

  const analysisStatus = useSelector((state) => state.websiteAnalysis.status);
  const isLoading =
    analysisStatus === "loading" || analysisStatus === "initiated";

  // Combine original data with modificationss
  const editableData = analysisData
    ? {
        ...analysisData.replacements,
        ...modifications,
        challenges: modifications.challenges || analysisData.challenges || [],
      }
    : {};

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(
      { modifications: { ...modifications, callType, callDifficulty } },
      3,
    );
  };

  const handleFieldChange = (field, value) => {
    setModifications((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Helper function to render field or skeleton
  const renderField = (field, component) => {
    if (isLoading && !modifications[field]) {
      return (
        <MKBox sx={{ width: "100%" }}>
          <MKTypography variant="caption" color="text">
            {field.replace(/_/g, " ").toUpperCase()}
          </MKTypography>
          <LoadingSkeleton />
        </MKBox>
      );
    }
    return component;
  };

  return (
    <MotionGrid
      container
      item
      xs={12}
      lg={7}
      sx={{ mx: "auto" }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
    >
      <MKBox width="100%" component="form" onSubmit={handleSubmit}>
        {/* Personal Information Card */}
        <Card sx={{ mb: 4 }}>
          <MKBox p={4}>
            <MKTypography variant="h5" color="dark" mb={4} textAlign="center">
              Personal Information
            </MKTypography>

            {/* Avatar and Name Section */}
            <MKBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={4}
              gap={2}
            >
              <MKAvatar
                src={persona?.avatarUrl || ""}
                alt="profile-image"
                size="xxl"
                shadow="xl"
              />
              <MKTypography variant="body2" color="text.secondary">
                {persona?.name || editableData?.full_name || ""}
              </MKTypography>
            </MKBox>

            {/* Personal Details Grid */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {renderField(
                  "title",
                  <EditableField
                    label="Title:"
                    value={editableData?.title || ""}
                    onChange={(value) => handleFieldChange("title", value)}
                    validationType="title"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {renderField(
                  "age",
                  <EditableField
                    label="Age:"
                    value={editableData?.age || ""}
                    onChange={(value) => handleFieldChange("age", value)}
                    validationType="age"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {renderField(
                  "location",
                  <EditableField
                    label="Location:"
                    value={editableData?.location || ""}
                    onChange={(value) => handleFieldChange("location", value)}
                    validationType="location"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
            </Grid>
          </MKBox>
        </Card>

        {/* Call Type Card */}
        <Card sx={{ mb: 4 }}>
          <MKBox p={3}>
            <MKTypography variant="h5" color="dark" mb={3}>
              Call Settings
            </MKTypography>

            {/* Call Type Select */}
            <MKBox mb={3}>
              <MKTypography variant="body2" color="text" mb={1}>
                Call Type
              </MKTypography>
              <FormControl fullWidth>
                <Select
                  value={callType}
                  onChange={(e) => {
                    setCallType(e.target.value);
                    handleFieldChange("callType", e.target.value);
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    height: "45px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                >
                  <MenuItem value="cold_call">Cold Call</MenuItem>
                  <MenuItem value="discovery" disabled>
                    Discovery Call (Clients Only)
                  </MenuItem>
                  <MenuItem value="demo" disabled>
                    Product Demo (Clients Only)
                  </MenuItem>
                  <MenuItem value="consultation" disabled>
                    Warm Call (Clients Only)
                  </MenuItem>
                  <MenuItem value="followup" disabled>
                    Follow-up Meeting (Clients Only)
                  </MenuItem>
                  <MenuItem value="negotiation" disabled>
                    Negotiation (Clients Only)
                  </MenuItem>
                  <MenuItem value="pitch_practice" disabled>
                    Pitch Practice (Clients Only)
                  </MenuItem>
                </Select>
              </FormControl>
            </MKBox>

            {/* Call Difficulty Select */}
            <MKBox>
              <MKTypography variant="body2" color="text" mb={1}>
                Difficulty Level
              </MKTypography>
              <FormControl fullWidth>
                <Select
                  value={callDifficulty}
                  onChange={(e) => {
                    setCallDifficulty(e.target.value);
                    handleFieldChange("callDifficulty", e.target.value);
                  }}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    height: "45px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                >
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium" disabled>
                    Medium (Clients Only)
                  </MenuItem>
                  <MenuItem value="hard" disabled>
                    Hard (Clients Only)
                  </MenuItem>
                </Select>
              </FormControl>
              <MKTypography
                variant="caption"
                color="text"
                mt={1}
                display="block"
              >
                Select the difficulty level for your call simulation
              </MKTypography>
            </MKBox>
          </MKBox>
        </Card>

        {/* Company Information Card */}
        <Card sx={{ mb: 4 }}>
          <MKBox p={3} pr={4}>
            <MKTypography variant="h5" color="dark" mb={3}>
              Company Information
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {renderField(
                  "company_name",
                  <EditableField
                    label="Company Name:"
                    value={editableData?.company_name || ""}
                    onChange={(value) =>
                      handleFieldChange("company_name", value)
                    }
                    validationType="companyName"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {renderField(
                  "number_employees",
                  <EditableField
                    label="Number of Employees:"
                    value={editableData?.number_employees || ""}
                    onChange={(value) => {
                      const formatted = value.replace(/[^0-9,+]/g, "");
                      handleFieldChange("number_employees", formatted);
                    }}
                    helperText="Enter number (e.g., 100 or 1,000+)"
                    validationType="numberEmployees"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
              <Grid item xs={12}>
                {renderField(
                  "company_description",
                  <EditableField
                    label="Company Description:"
                    value={editableData?.company_description || ""}
                    onChange={(value) =>
                      handleFieldChange("company_description", value)
                    }
                    multiline
                    rows={4}
                    validationType="description"
                    validationRules={validationRules}
                  />,
                )}
              </Grid>
            </Grid>
          </MKBox>
        </Card>

        {/* Challenges Card */}
        <Card sx={{ mb: 4 }}>
          <MKBox p={3}>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
            >
              <MKTypography variant="h5" color="dark">
                Key Challenges
              </MKTypography>
              {!isLoading && (
                <MKButton
                  variant="text"
                  color="info"
                  size="small"
                  onClick={() => {
                    const newChallenges = [
                      ...(editableData.challenges || []),
                      "",
                    ];
                    handleFieldChange("challenges", newChallenges);
                  }}
                >
                  + Add Challenge
                </MKButton>
              )}
            </MKBox>
            {renderField(
              "challenges",
              <Grid container spacing={2}>
                {(editableData.challenges || []).map((challenge, index) => (
                  <Grid item xs={12} key={index}>
                    <MKBox display="flex" alignItems="center" gap={2}>
                      <MKBox flexGrow={1}>
                        <EditableField
                          label={`Challenge ${index + 1}:`}
                          value={challenge}
                          onChange={(value) => {
                            const newChallenges = [...editableData.challenges];
                            newChallenges[index] = value;
                            handleFieldChange("challenges", newChallenges);
                          }}
                          fullWidth
                          multiline
                          rows={2}
                          validationType="challenge"
                          validationRules={validationRules}
                        />
                      </MKBox>
                    </MKBox>
                  </Grid>
                ))}
              </Grid>,
            )}
          </MKBox>
        </Card>

        {/* Submit Button */}
        <MKBox textAlign="center" mt={4}>
          <MKButton
            type="submit"
            variant="gradient"
            color="dark"
            sx={{ width: "200px" }}
            component={motion.button}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading}
          >
            Complete Setup
          </MKButton>
        </MKBox>
      </MKBox>
    </MotionGrid>
  );
}

StepThree.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  persona: PropTypes.shape({
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
};

StepThree.defaultProps = {
  persona: {},
};

export default StepThree;
